import {
  BicycleSvg,
  Button,
  ButtonVariant,
  EBorders,
  EFont,
  EPaxType,
  ESpacing,
  ETripType,
  Font,
  IBEPax,
  Journey,
  PlaneSvg,
  Tag,
  TickCircleIcon,
  TripDetail,
} from "@hkexpressairwayslimited/ui";
import { Stack, SvgIcon } from "@mui/material";
import { add } from "lib/common/helper";
import { getTripTitle } from "lib/features/flight-book/helper";
import { useTransContent } from "modules/common/trans-content/transContent";
import { getStoredJourneyArItems, useJourneyCost } from "modules/features/flightBooking/calculateCost/useJourneyCost";
import { SelectedArItems } from "modules/features/flightBooking/reducers";
import { useCurrency } from "modules/global/currency/useCurrency";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { updateAddExtrasPageMetaData } from "store/sessionStorage/slices/flightBookingSlice";
import {
  OnSportSelectedData,
  SelectItem,
  SportCloseEvent,
  Summary,
  formatJourneySelectedAndPurchasedArItemSummary,
} from ".";
import { EArItemType, EOtherArCode, JourneyConf, NavButtons, paxTypeIconMapping } from "..";
import { Drawer } from "../../Drawer";

type SportDrawerProps = {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  passengers: IBEPax[];
  switcher?: JSX.Element;
  currentConf?: JourneyConf<SportNavButtonData>;
  onPaxSelectSport: (paxId: string, added: boolean, code?: string, journeyKey?: string) => void;
  confirmLabel?: string | JSX.Element | JSX.Element[];
  autoClose?: boolean;
  title?: JSX.Element | string;
};
export function SportSection({
  code,
  price,
  passengers,
  tripDetail,
  tripType,
  onSportSelected,
  selectedSportArItem = [],
  onSportConfirmed = (b: boolean) => {},
  onOpenSportDrawer,
  onClose,
  sportConfirmed,
}: {
  code: EOtherArCode;
  price?: string | JSX.Element;
  passengers: IBEPax[];
  tripDetail: TripDetail;
  tripType: ETripType;
  selectedSportArItem: SelectedArItems[];
  onSportConfirmed?: (b: boolean) => void;
  onSportSelected: (data: OnSportSelectedData) => void;
  onOpenSportDrawer?: () => void;
  onClose?: SportCloseEvent;
  sportConfirmed?: boolean;
}) {
  const dispatch = useDispatch();
  const [confirmed, setConfirmed] = useState(Boolean(sportConfirmed));
  const [open, setOpen] = useState(false);
  const sportCost = useJourneyCost(EArItemType.SPORT_EQUIPMENT, getStoredJourneyArItems(tripDetail.journeys));
  const { P } = useCurrency();
  const { t } = useTransContent();
  useEffect(() => {
    if (confirmed === true) {
      dispatch(
        updateAddExtrasPageMetaData({
          confirmedItems: {
            [EArItemType.SPORT_EQUIPMENT]: true,
          },
        })
      );
      setOpen(false);
    }
  }, [confirmed, dispatch]);

  const journeyConf = useMemo(() => {
    const totalSelectAmount = selectedSportArItem.reduce(
      (a, c) =>
        c.arItems.reduce((a1, c1) => {
          if (c1.byPax) {
            return add(
              a1,
              c1.byPax.reduce((a2, c2) => add(a2, c2.amount), 0)
            );
          }
          return a1;
        }, a),
      0
    );

    return tripDetail.journeys.map((e) => {
      const selectAmount =
        selectedSportArItem
          .find((n) => n.journeyKey === e.journey_key)
          ?.arItems.reduce((a, c) => {
            if (c.byPax) {
              return add(
                a,
                c.byPax.reduce((a1, c1) => add(a1, c1.amount), 0)
              );
            }
            return a;
          }, 0) ?? 0;
      const {
        availability = Infinity,
        price = 0,
        code,
      } = e.arItems.available.find((e) => e.type === EArItemType.SPORT_EQUIPMENT) ?? {};
      return {
        label: `${e.origin} - ${e.destination}`,
        selectAmount,
        id: e.journey_key,
        index: e.index,
        origin: e.origin,
        destination: e.destination,
        fare: e.fare,
        data: {
          code,
          unavailable: selectAmount >= (availability ?? Infinity),
          totalCost: P(totalSelectAmount * price, undefined, false, 0, true),
          price: P(price, undefined, false, 0, true),
          selected: selectedSportArItem.find((n) => n.journeyKey === e.journey_key)?.arItems[0].byPax,
          purchased: e.arItems.purchased.filter((e) => e.type === EArItemType.SPORT_EQUIPMENT)[0]?.byPax,
          isAllPassengerPurchased: (() => {
            const purchasedUsers = e.arItems.purchased.filter((e) => e.type === EArItemType.SPORT_EQUIPMENT)[0]?.byPax;
            return passengers.every((passenger) => {
              return purchasedUsers && purchasedUsers.find((purchasedUser) => purchasedUser.paxId === passenger.id);
            });
          })(),
          isSomePassengerPurchased: (() => {
            const purchasedUsers = e.arItems.purchased.filter((e) => e.type === EArItemType.SPORT_EQUIPMENT)[0]?.byPax;
            return passengers.some((passenger) => {
              return purchasedUsers && purchasedUsers.find((purchasedUser) => purchasedUser.paxId === passenger.id);
            });
          })(),
          isSomePassengerSelected: (() => {
            const selectedUsers = e.arItems.selected.filter((e) => e.type === EArItemType.SPORT_EQUIPMENT)[0]?.byPax;
            return selectedUsers && selectedUsers?.reduce((a, i) => add(a, i.amount), 0) > 0;
          })(),
        },
      };
    });
  }, [P, passengers, selectedSportArItem, tripDetail.journeys]);
  const [selectedJourney, setSelectedJourney] = useState(journeyConf[0].id);
  const currentConf = useMemo(() => journeyConf.find((n) => n.id === selectedJourney), [journeyConf, selectedJourney]);
  const isAllPurchased = journeyConf.every((item) => item.data.isAllPassengerPurchased);
  const isSomePurchased = journeyConf.some((item) => item.data.isSomePassengerPurchased);
  const isSomeSelected = journeyConf.some((item) => item.data.isSomePassengerSelected);
  const handlePaxSelectSport = useCallback(
    (paxId: string, added: boolean, code?: string, journeyKey?: string) => {
      if (journeyKey && code) {
        // dispatch(updateSport({ journeyKey, paxId, added, code }));
        onSportSelected({ tripId: tripDetail.id, journeyKey, paxId, added, code });
      }
    },
    [onSportSelected, tripDetail.id]
  );
  return (
    <SelectItem
      totalCost={P(sportCost, undefined, false, 0, true)}
      code={code}
      price={price}
      onClick={() => {
        setOpen(true);
        onOpenSportDrawer && onOpenSportDrawer();
      }}
      summary={
        (confirmed && isSomeSelected) || isSomePurchased ? (
          <SportSummary journeys={tripDetail.journeys} tripType={tripType} passengers={passengers} />
        ) : undefined
      }
      isAllPurchased={isAllPurchased}
    >
      <SportDrawer
        autoClose={true}
        passengers={passengers}
        open={open}
        onClose={() => {
          onClose && onClose({ tripId: tripDetail.id, code });
          setOpen(false);
        }}
        onConfirm={() => {
          setConfirmed(true);
          setOpen(false);
          onSportConfirmed(true);
        }}
        onPaxSelectSport={handlePaxSelectSport}
        switcher={
          <NavButtons<SportNavButtonData>
            buttons={journeyConf as JourneyConf<SportNavButtonData>[]}
            selected={selectedJourney}
            onClick={(id: string) => setSelectedJourney(id)}
          />
        }
        currentConf={currentConf}
        title={
          <Stack>
            <Font color='purple.default' fontWeight='bolder'>
              {
                t(
                  `web.flightBook.flightSelect.flightDetailPopUp.${getTripTitle(currentConf?.index ?? 0, tripType)}`
                ) as string
              }
            </Font>
            <Stack direction='row' spacing={ESpacing._2xs}>
              <Font variant={EFont.h3} fontWeight='bolder' sx={{ fontSize: ["16px", "16px", "22px"] }}>
                {t(`airportCodeToCityName.${currentConf?.origin}`)} {t("web.home.bookATrip.to")}{" "}
                {t(`airportCodeToCityName.${currentConf?.destination}`)}
              </Font>
              <Tag
                key={currentConf?.fare.bundle_offers[0].type}
                variant='promo_message'
                value={
                  currentConf?.fare.bundle_offers[0].type
                    ? t(`${currentConf?.fare.bundle_offers[0].type}.title`)
                    : "****"
                }
              />
            </Stack>
          </Stack>
        }
      />
    </SelectItem>
  );
}
type SportNavButtonData = {
  unavailable: boolean;
  totalCost: string | JSX.Element;
  price: string | JSX.Element;
  code?: string;
  selected:
    | {
        paxId: string;
        amount: number;
      }[]
    | undefined;
  purchased?:
    | {
        paxId: string;
        amount: number;
      }[]
    | undefined;
};
export function SportDrawer({
  open,
  onClose,
  onConfirm,
  passengers,
  switcher,
  currentConf,
  onPaxSelectSport,
  confirmLabel,
  autoClose = false,
  title,
}: SportDrawerProps) {
  const userSelected = useCallback(
    (paxId: string) => {
      if (currentConf?.data?.selected) {
        return !!currentConf?.data?.selected.find((e) => e.paxId === paxId);
      }
      return false;
    },
    [currentConf]
  );
  const userPurchased = useCallback(
    (paxId: string) => {
      if (currentConf?.data?.purchased) {
        return !!currentConf?.data?.purchased.find((e) => e.paxId === paxId);
      }
      return false;
    },
    [currentConf]
  );
  const { t } = useTransContent();
  const { P } = useCurrency();
  return (
    <Drawer
      autoClose={autoClose}
      open={open}
      onClose={onClose}
      title={`${t("web.addEXtras.SportDrawer.header.title")}`}
      avatar={<BicycleSvg />}
      totalCost={currentConf?.data?.totalCost ?? ""}
      onConfirm={onConfirm}
      confirmLabel={t("web.manageMyBooking.myTrips.addItems.btn")}
      crossIconClose={true}
    >
      <Stack spacing={ESpacing._sm}>
        <Font whiteSpace={"nowrap"}>{t("web.addEXtras.SportDrawer.content")}</Font>
        {title}
        {switcher}
        {/* <NavButtons<SportNavButtonData>
          buttons={journeyConf as JourneyConf<SportNavButtonData>[]}
          selected={selectedJourney}
          onClick={(id: string) => setSelectedJourney(id)}
        /> */}
        <Stack>
          {passengers.map((e) => {
            return (
              <SportSelectorItem
                key={currentConf?.id + e.id}
                passenger={e}
                selected={userSelected(e.id) || userPurchased(e.id)}
                purchased={userPurchased(e.id)}
                onClick={(paxId: string, added: boolean) =>
                  onPaxSelectSport(paxId, added, currentConf?.data?.code, currentConf?.id)
                }
                unavailable={currentConf?.data?.unavailable}
                price={currentConf?.data?.price}
              />
            );
          })}
        </Stack>
      </Stack>
    </Drawer>
  );
}

function SportSelectorItem({
  passenger: { id, paxType, courtesy, surname, givenName },
  selected,
  onClick,
  price,
  unavailable,
  purchased,
}: {
  passenger: IBEPax;
  selected: boolean;
  onClick: (paxId: string, added: boolean) => void;
  price?: string | number | JSX.Element;
  unavailable?: boolean;
  purchased?: boolean;
}) {
  const [added, setAdded] = useState(selected);
  const { t } = useTransContent();
  const addedBackgroundObj = !(purchased || (unavailable && !added)) && added ? { backgroundColor: "#E2D5E9" } : {};
  return (
    <Stack
      sx={{ paddingY: ESpacing._sm, borderBottom: EBorders.b1 }}
      direction={["column", "column", "row"]}
      justifyContent='space-between'
      rowGap={2}
    >
      <Stack direction='row' alignItems='center' spacing={ESpacing._2xs} rowGap={2}>
        {paxTypeIconMapping[paxType as EPaxType]}
        <Font
          variant={EFont.h3}
          fontWeight='fontWeightBold'
          sx={{ fontSize: ["18px", "18px", "22px"] }}
        >{`${courtesy} ${surname} ${givenName}`}</Font>
      </Stack>
      <Stack direction='row' alignItems='center' justifyContent='space-between' spacing={ESpacing._2xs}>
        <Font variant={EFont.h2} color='purple.default' fontWeight='600'>
          {price}
        </Font>
        <Button
          style={{ minWidth: "96px", ...addedBackgroundObj }}
          variant={ButtonVariant.Secondary}
          disabled={purchased || (unavailable && !added)}
          onClick={() => {
            if (purchased || (unavailable && !added)) return;
            setAdded(!added);
            onClick && onClick(id, added);
          }}
        >
          {added || purchased
            ? t("web.flightBooking.otherSection.button.added")
            : t("web.flightBooking.otherSection.button.add")}
        </Button>
      </Stack>
    </Stack>
  );
}

export function SportSummary({
  journeys,
  passengers,
  tripType,
}: {
  journeys: Journey[];
  passengers: IBEPax[];
  tripType: ETripType;
}) {
  const SportArItemSummary = formatJourneySelectedAndPurchasedArItemSummary(
    journeys,
    EArItemType.SPORT_EQUIPMENT,
    tripType,
    passengers
  );
  const { t } = useTransContent();
  return (
    <Summary>
      <Stack direction='row' spacing={ESpacing._2xs}>
        <SvgIcon sx={{ color: "purple.default" }}>
          <TickCircleIcon />
        </SvgIcon>
        <Stack spacing={ESpacing._2xs} width='100%'>
          <Font color='purple.default' fontWeight={"600"}>
            {t("web.addEXtras.SportSummary.add")}
          </Font>
          {SportArItemSummary.map(
            (e) =>
              e?.amount > 0 && (
                <Stack key={e.label} direction='row' justifyContent='space-between'>
                  <Stack direction='row' spacing={ESpacing._2xs}>
                    <SvgIcon sx={{ color: "neutral.black" }}>
                      <PlaneSvg />
                    </SvgIcon>
                    <Font fontWeight='fontWeightBold'>
                      {t(`web.flightBook.flightSelect.flightDetailPopUp.${e.label}`)}
                    </Font>
                  </Stack>
                  <Font>X{e.amount}</Font>
                </Stack>
              )
          )}
        </Stack>
      </Stack>
    </Summary>
  );
}
