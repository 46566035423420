import {
  BundleOffer,
  COUNTRY_CODE,
  DateRange,
  ECourtesy,
  EPaxType,
  ETripType,
  IBEPax,
  MMBPax,
  PassengerCount,
  TripDetail,
} from "@hkexpressairwayslimited/ui";
import {
  Companion,
  DetailPageCompanion,
  PassengerAgeLimitation,
  PassengerPageConf,
} from "[lang]/flight-booking/detail/definition";
import { addDays, addMinutes, addYears, differenceInYears, isBefore } from "date-fns";

import { formateDateFormat2 } from "lib/features/flight-book/flight-select/helper";
import {
  BookingInfo,
  EFareType,
  ETripStatus,
  ManageMyBookingTripDetail,
  TripItemType,
} from "lib/features/manage-my-booking/my-trips/definition";
import { toUpper } from "lodash";
import { DEFAULT_NEXT_DAY } from "modules/common/const";
import { checkTripType } from "./payment/helper";

export const manageMyBookingFlightMissingDetails = "manageMyBookingFlightMissingDetails";

export const saveFlightMissingBooking = (data: any = {}, tripData: TripItemType) => {
  const { bookingRef, firstName, lastName } = data;
  const details = JSON.parse(localStorage.getItem(manageMyBookingFlightMissingDetails) || "[]");
  for (let i = 0; i < details.length; i++) {
    if (
      details[i].bookingRef === bookingRef &&
      details[i].firstName === firstName &&
      details[i].lastName === lastName
    ) {
      details[i].tripData = tripData;
      details[i].expiredDate = addMinutes(new Date(), 15);
      localStorage.setItem(manageMyBookingFlightMissingDetails, JSON.stringify(details));
      return;
    }
  }

  details.push({
    bookingRef,
    firstName,
    lastName,
    tripData,
    expiredDate: addMinutes(new Date(), 15),
  });
  localStorage.setItem(manageMyBookingFlightMissingDetails, JSON.stringify(details));
};

export function capitalizeFirstLetter(string: string) {
  if (!string) {
    return string;
  }
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

export const delExpiredFlightMissingBooking = () => {
  const details = JSON.parse(localStorage.getItem(manageMyBookingFlightMissingDetails) || "[]");
  const filterDetails = details.filter((detail: any) => isBefore(new Date(), new Date(detail.expiredDate)));
  localStorage.setItem(manageMyBookingFlightMissingDetails, JSON.stringify(filterDetails));
};

export const getFlightMissingBookingTripData = () => {
  const details = JSON.parse(localStorage.getItem(manageMyBookingFlightMissingDetails) || "[]");
  const tripDataArr = details?.map((detail: any) => detail?.tripData);
  return tripDataArr || [];
};

export const constructTripData = (booking: ManageMyBookingTripDetail, imageURL: string): TripItemType => {
  const tripType = checkTripType(booking);
  return {
    picUrl: imageURL,
    tripType: tripType ? tripType : ETripType.roundTrip,
    tripStatus:
      booking.payment_detail.status === "UNSETTLED"
        ? ETripStatus.unsettled
        : booking.payment_detail.status === "ON_HOLD"
          ? ETripStatus.onHold
          : booking.payment_detail.status === "FLOWN"
            ? ETripStatus.flown
            : ETripStatus.confirmed,
    destination: booking.journeys[0].destination,
    bookingRef: booking.sales_reference,
    departureDateTime: booking.journeys[0].segments[0].departure,
    trips: booking.journeys.map((journey) => {
      return {
        flightNumber: journey.segments.map((segment) => segment.flight_number).join(" "),
        fareType: getFareType(journey.fare.bundle_offers),
        date: journey.date,
        time: `${journey.segments[0].departure_time} - ${journey.segments[journey.segments.length - 1].arrival_time} ${journey.day_diff === 0 ? "" : `+${journey.day_diff}`}`,
        flightTime: `${journey.duration.hour}h` + (journey.duration.minute ? ` ${journey.duration.minute}m` : ""),
        segments: journey.segments,
        journey_eligibility: journey.journey_eligibility,
      };
    }),
    passengers: {
      adult: booking.passengers.reduce((a, c) => (c.paxType === EPaxType.Adult ? a + 1 : a), 0),
      child: booking.passengers.reduce((a, c) => (c.paxType === EPaxType.Child ? a + 1 : a), 0),
      infant: booking.passengers.reduce((a, c) => (c.paxType === EPaxType.Infant ? a + 1 : a), 0),
    },
  };
};

export const constructUpdatePassengerDetailsBody = (booking: ManageMyBookingTripDetail, data: any) => {
  const infantList = data.passengers.filter((pax: MMBPax) => pax.paxType === EPaxType.Infant);
  const passengerList = data.passengers.filter(
    (pax: MMBPax) => pax.paxType !== EPaxType.Infant && pax.paxType !== EPaxType.Child
  );
  const childList = data.passengers.filter((pax: MMBPax) => pax.paxType === EPaxType.Child);

  const newBody = {
    sales_reference: booking.sales_reference,
    booking_first_name: booking?.contact?.first_name ?? passengerList[0]?.givenName ?? "",
    booking_last_name: booking?.contact?.last_name ?? passengerList[0]?.surname ?? "",
    passengers: passengerList.map((pax: MMBPax) => ({
      passenger_key: pax.id,
      info: {
        title: toUpper(pax.courtesy),
        date_of_birth: formateDateFormat2(pax.DOB),
      },
      contact_info: {
        phone_number:
          pax?.mobile?.countryCode && pax?.mobile.mobileNumber
            ? {
                country_code: pax.mobile.countryCode,
                number: pax.mobile.mobileNumber,
              }
            : null,
        email_address: pax.email,
      },
      travel_doc:
        pax.docType && pax.docIssuingCountry && pax.docNationality && pax.docNumber && pax.docExpirationDate
          ? {
              doc_type: pax.docType,
              issuing_country: pax.docIssuingCountry,
              doc_nationality: pax.docNationality,
              doc_number: pax.docNumber,
              expire_date: formateDateFormat2(pax.docExpirationDate),
            }
          : null,
    })),
    ...(booking?.contact
      ? {
          contact_person: {
            phone_number:
              data.contactsMobile.countryCode && data.contactsMobile.mobileNumber
                ? {
                    country_code: data.contactsMobile.countryCode,
                    number: data.contactsMobile.mobileNumber,
                  }
                : null,
            first_name: data.contactsGivenName,
            last_name: data.contactsSurname,
            email_address: data.contactsEmail,
            title: toUpper(data.contactsCourtesy),
            is_passenger: data.passengers.some(
              (pax: MMBPax) =>
                data.contactsCourtesy === pax.courtesy &&
                data.contactsEmail === pax.email &&
                data.contactsGivenName === pax.givenName &&
                data.contactsSurname === pax.surname &&
                data.contactsMobile.countryCode === pax?.mobile?.countryCode &&
                data.contactsMobile.mobileNumber === pax?.mobile?.mobileNumber
            ),
          },
        }
      : {}),
  };
  const childBody = childList.map((pax: MMBPax) => {
    return {
      passenger_key: pax.id,
      info: {
        title: toUpper(pax.courtesy),
        date_of_birth: formateDateFormat2(pax.DOB),
      },

      travel_doc:
        pax.docType && pax.docIssuingCountry && pax.docNationality && pax.docNumber && pax.docExpirationDate
          ? {
              doc_type: pax.docType,
              issuing_country: pax.docIssuingCountry,
              doc_nationality: pax.docNationality,
              doc_number: pax.docNumber,
              expire_date: formateDateFormat2(pax.docExpirationDate),
            }
          : null,
    };
  });

  newBody.passengers = [...newBody.passengers, ...childBody];

  infantList.forEach((infant: MMBPax, index: number) => {
    newBody.passengers[index].infant = {
      info: {
        title: toUpper(infant.courtesy),
        date_of_birth: formateDateFormat2(infant.DOB),
      },
      travel_doc:
        infant.docType &&
        infant.docIssuingCountry &&
        infant.docNationality &&
        infant.docNumber &&
        infant.docExpirationDate
          ? {
              doc_type: infant.docType,
              issuing_country: infant.docIssuingCountry,
              doc_nationality: infant.docNationality,
              doc_number: infant.docNumber,
              expire_date: formateDateFormat2(infant.docExpirationDate),
            }
          : null,
    };
  });

  return newBody;
};

export function formatPageConf(
  pageConf: PassengerPageConf,
  originDate = DEFAULT_NEXT_DAY,
  returnDate?: Date
): PassengerPageConf<DateRange> {
  Object.keys(pageConf.date).forEach((paxType) => {
    const currentDate = pageConf.date[paxType as EPaxType] as { min?: number; max?: number } & DateRange;
    const { max = undefined, min = undefined } = currentDate;

    if (returnDate && paxType === EPaxType.Infant) {
      currentDate.start = max ? addDays(addYears(returnDate, max * -1), 1) : undefined;
    } else {
      currentDate.start = max ? addDays(addYears(originDate, max * -1), 1) : undefined;
    }

    if (paxType === "contactDob") {
      currentDate.end = min ? addYears(new Date(), min * -1) : undefined;
    } else {
      currentDate.end = min ? addYears(originDate, min * -1) : originDate;
      if (paxType === EPaxType.Infant && isBefore(new Date(), currentDate.end)) {
        currentDate.end = new Date();
      }
    }
  });

  if (pageConf.date.promotionAge) {
    pageConf.date.promotionAge.forEach((promotionAge) => {
      const currentDate = promotionAge as { min?: number; max?: number } & DateRange;
      const { max = undefined, min = undefined } = currentDate;

      currentDate.start = max ? addYears(originDate, max * -1) : undefined;
      currentDate.end = min ? addYears(originDate, min * -1) : originDate;
    });
  }
  return pageConf;
}

export function formatCompanion(
  conf: PassengerAgeLimitation,
  users?: Companion[],
  startDay?: Date,
  noAgeFilter?: boolean
): DetailPageCompanion {
  if (!users) return {};
  if (noAgeFilter === true) {
    return {
      [EPaxType.Adult]: users,
      [EPaxType.Child]: users,
      [EPaxType.Infant]: undefined,
    };
  }
  const today = new Date(startDay ?? "");
  const companions: DetailPageCompanion = {
    [EPaxType.Adult]: [],
    [EPaxType.Child]: [],
    [EPaxType.Infant]: [],
  };
  users.forEach((user) => {
    const age = differenceInYears(today, user.DOB);
    Object.values(EPaxType).forEach((title) => {
      if (!title) return;
      const { max = undefined, min = undefined } = conf[title];
      if (!max && !min) return;
      if ((min && max && age > min && age < max) || (!min && max && age < max) || (!max && min && age > min)) {
        companions[title]?.push({
          ...user,
          title,
        });
      }
    });
  });
  return companions;
}

export const constructMMBFlightSearchBody = (
  bookingInfo: BookingInfo,
  firstJourneyDate?: string,
  secondJourneyData?: string
) => {
  const { sales_reference, last_name, first_name } = bookingInfo;

  const newBody =
    first_name && last_name
      ? {
          sales_reference: sales_reference,
          first_name: first_name,
          last_name: last_name,
          ...(firstJourneyDate !== undefined && { first_flight: { date: firstJourneyDate } }),
          ...(secondJourneyData !== undefined && { second_flight: { date: secondJourneyData } }),
        }
      : {
          sales_reference: sales_reference,
          ...(firstJourneyDate !== undefined && { first_flight: { date: firstJourneyDate } }),
          ...(secondJourneyData !== undefined && { second_flight: { date: secondJourneyData } }),
        };

  return newBody;
};

export const separateCountryCode = (phoneNo: string) => {
  const countryCodes = COUNTRY_CODE.map((e) => e.value);
  const countryCodeRegex = new RegExp("^(" + countryCodes.join("|") + ")");
  const countryCodeMatch = phoneNo ? phoneNo.match(countryCodeRegex) : null;
  const countryCode = countryCodeMatch ? countryCodeMatch[0] : null;
  const phoneNumber = countryCode && phoneNo ? phoneNo.replace(countryCode, "") : phoneNo;
  const country = COUNTRY_CODE.find((e) => e.value === countryCode)?.country;

  return { countryCode, phoneNumber, country };
};

export const constructChangeFlightBody = (
  firstJourneyDate: string | null,
  secondJourneyDate: string | null,
  tripDetail: TripDetail
) => {
  const firstJourney =
    firstJourneyDate === null
      ? {}
      : {
          first_journey: {
            journey_key: tripDetail.journeys[0].journey_key,
            fare_availability_key: tripDetail.journeys[0].fare.fare_availability_key ?? "",
            date: firstJourneyDate,
          },
        };

  const secondJourney =
    secondJourneyDate === null
      ? {}
      : {
          second_journey: {
            journey_key:
              tripDetail.journeys.length > 1 ? tripDetail.journeys[1].journey_key : tripDetail.journeys[0].journey_key,
            fare_availability_key:
              tripDetail.journeys.length > 1
                ? tripDetail.journeys[1].fare.fare_availability_key
                : tripDetail.journeys[0].fare.fare_availability_key,
            date: secondJourneyDate,
          },
        };

  return { ...firstJourney, ...secondJourney };
};

export function getPassengerCount(pax?: MMBPax[] | IBEPax[]): PassengerCount {
  return {
    Adult: pax?.filter((p) => p.paxType === EPaxType.Adult).length ?? 0,
    Child: pax?.filter((p) => p.paxType === EPaxType.Child).length ?? 0,
    Infant: pax?.filter((p) => p.paxType === EPaxType.Infant).length ?? 0,
  };
}

export function getFareType(bundle: BundleOffer[] | BundleOffer) {
  const bundleOffer = Array.isArray(bundle) ? bundle[0] : bundle;
  return bundleOffer.type === "ESSE"
    ? EFareType.essential
    : bundleOffer.type === "LITE"
      ? EFareType.lite
      : bundleOffer.type === "MAX"
        ? EFareType.max
        : bundleOffer.type === "U_LITE"
          ? EFareType.ultraLite
          : bundleOffer.type === "FUN"
            ? EFareType.fun
            : bundleOffer.type === "FUNFLEX"
              ? EFareType.funflex
              : null;
}

export function getCourtesy(title: string) {
  if (!title) {
    return undefined;
  }
  const capTitle = capitalizeFirstLetter(title);
  return capTitle === "Mr"
    ? ECourtesy.Mr
    : capTitle === "Ms"
      ? ECourtesy.Ms
      : capTitle === "Mrs"
        ? ECourtesy.Mrs
        : capTitle === "Miss"
          ? ECourtesy.Miss
          : ECourtesy.Master;
}

export function checkExitRowExist(trip: ManageMyBookingTripDetail, pax: MMBPax) {
  const checkSeat = trip.journeys.flatMap((journey) =>
    journey.segments.map((segment) => {
      const targetSeat = segment.arItems.purchased.find((e) => {
        if (e.type === "SEAT" && e.byPax && e.byPax[0].paxId === pax.id && e.is_exit_row) return e;
      });

      if (targetSeat) {
        return true;
      } else {
        return false;
      }
    })
  );

  return checkSeat.includes(true);
}
