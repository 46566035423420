import { ArItem, ArItems } from "@hkexpressairwayslimited/ui";
import { add, multiply } from "lib/common/helper";
type ArItemsKeys = keyof ArItems;
const arItemFilter = (arItems: ArItems, key: ArItemsKeys = "selected") => arItems[key];
export function calcAllPickedArItems(
  allArItems?: ArItems[],
  itemFilter?: (arItem: ArItem) => boolean,
  withCost = false,
  arItemKey: ArItemsKeys = "selected"
) {
  if (!allArItems || !allArItems.length) return 0;
  return allArItems.reduce(
    (a, arItems) =>
      add(
        a,
        (itemFilter
          ? arItemFilter(arItems, arItemKey).filter((arItem) => itemFilter(arItem))
          : arItemFilter(arItems, arItemKey)
        ).reduce(
          (a1, arItem) =>
            add(
              a1,
              multiply(
                arItem.byPax?.reduce((a2, c2) => add(a2, c2.amount), 0) ?? 0,
                withCost
                  ? arItems.available.find((availableArItem) => availableArItem?.code === arItem.code)?.price ?? 0
                  : 1
              )
            ),
          0
        )
      ),
    0
  );
}
