import currency from "currency.js";
import { getPrecision, multiply } from "lib/common/helper";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { selectCurrencyDetail } from "store/sessionStorage/slices/currency";
import { useDcc } from "ui/features/flight-booking/payment/payment-method-card/credit-card-form/DCC/Dcc";
const M = require("mathjs");

export function CalPrice(amount: number, rate: number, roundingFactor?: number) {
  if (!roundingFactor) {
    return multiply(amount, rate);
  }
  return parseFloat((Math.round((amount * rate) / roundingFactor) * roundingFactor).toFixed(9));
}

export function useCurrency() {
  const {
    selectedCurrency,
    bookingCurrency,
    asiaMiles,
    currentRate = 1,
    roundingFactor = 0.01,
  } = useSelector(selectCurrencyDetail);
  const withDcc = useDcc();
  return {
    D: useCallback(
      (
        amount: string | number,
        from: string = bookingCurrency,
        to: string = selectedCurrency,
        forceRoundFactor?: number
      ) => {
        const nAmount = parseFloat(amount + "");
        if (from === to) {
          return {
            toCurrency: to,
            fromCurrency: from,
            rate: 1,
            price: nAmount,
          };
        }

        return {
          toCurrency: to,
          fromCurrency: from,
          rate: currentRate,
          originPrice: nAmount,
          price: nAmount
            ? CalPrice(nAmount, currentRate, forceRoundFactor ? forceRoundFactor : withDcc ? undefined : roundingFactor)
            : 0,
        };
      },
      [bookingCurrency, currentRate, roundingFactor, selectedCurrency, withDcc]
    ),
    P: useCallback(
      (
        amount: string | number | undefined,
        forceCurrency?: string,
        isString?: boolean,
        forceRate?: number,
        bold?: boolean,
        masking?: boolean,
        toFixFactor: boolean | number = withDcc,
        noCenter?: boolean,
        paymentDetails?: boolean
      ) => {
        const forceNumberPrice = parseFloat(amount + "");
        if (isNaN(forceNumberPrice) && !masking) return forceNumberPrice + "";
        const price = amount
          ? CalPrice(forceNumberPrice, forceRate || currentRate, toFixFactor ? undefined : roundingFactor)
          : 0;
        const flexFixedFactor =
          price === 0
            ? 0
            : typeof toFixFactor === "boolean"
              ? toFixFactor
                ? getPrecision(price)
                : roundingFactor < 1
                  ? (1 / roundingFactor).toString().length - 1
                  : 0
              : toFixFactor;
        if (isString)
          return `${asiaMiles && forceCurrency === undefined && !paymentDetails ? `<i class='demo-icon icon-icon-asiamiles.originalimage-1'>&#xe800;</i>` : !asiaMiles || forceCurrency ? forceCurrency ?? selectedCurrency : ""}${
            masking
              ? "****"
              : currency(price, { precision: flexFixedFactor }).format({
                  symbol: " ",
                  separator: ",",
                  decimal: ".",
                })
          }`;
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: noCenter ? "inherit" : "center",
              fontWeight: bold ? "600" : "500",
            }}
          >
            <div style={{ marginRight: "4px" }}>
              {asiaMiles && forceCurrency === undefined ? (
                <i className='demo-icon icon-icon-asiamiles.originalimage-1'>&#xe800;</i>
              ) : (
                forceCurrency ?? selectedCurrency
              )}
            </div>
            <div>
              {masking
                ? "****"
                : currency(price, { precision: flexFixedFactor }).format({
                    symbol: "",
                    separator: ",",
                    decimal: ".",
                  })}
            </div>
          </div>
        );
      },
      [asiaMiles, currentRate, roundingFactor, selectedCurrency, withDcc]
    ),
  };
}
