import { ETripType, PassengerCount, SearchingDetail, TripDetail } from "@hkexpressairwayslimited/ui";
import { differenceInCalendarDays, format, isBefore } from "date-fns";
import { LowFareList } from "ui/features/flight-booking/flight-select/date-slider-selector/definition";
import {
  APIRequestBodyFlightSelectCreateOrder,
  APIRequestBodyLowFareAndFlightSearch,
  BaseTripRequestBody,
  FlightDetailAPIRequestBodyType,
  OrderPassenger,
} from "./definition";

function formateDate(date: string | Date) {
  const newDate = typeof date === "string" ? date.split("+")[0] : date;
  const time = new Date(newDate);
  const formattedDate = format(time, "EEE, MMM d, yyyy");
  return formattedDate;
}

function formateDateFormat2(date: string | Date, variant?: string) {
  if (!date) return "";
  const newDate = typeof date === "string" ? date.split("+")[0] : date;
  const time = new Date(newDate);
  const year = time.getFullYear();
  const month = (time.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-indexed in JavaScript
  const day = time.getDate().toString().padStart(2, "0");
  return variant === "slash" ? `${day}/${month}/${year}` : `${year}-${month}-${day}`;
}

function formateTime(date: string | Date) {
  const newDate = typeof date === "string" ? date.split("+")[0] : date;
  const time = new Date(newDate);

  const hours = time.getHours().toString().padStart(2, "0");
  const minutes = time.getMinutes().toString().padStart(2, "0");

  return `${hours}:${minutes}`;
}

function calculateTimeDifference(departure: string | Date, arrival: string | Date) {
  const departureDate = typeof departure === "string" ? new Date(departure.split("+")[0]) : new Date(departure);
  const arrivalDate = typeof arrival === "string" ? new Date(arrival.split("+")[0]) : new Date(arrival);
  let minutes = "";
  // Calculate the difference in milliseconds
  const differenceInMs = arrivalDate.getTime() - departureDate.getTime();

  // Convert the difference to hours and minutes
  const differenceInHours = Math.floor(differenceInMs / (1000 * 60 * 60));
  const differenceInMinutes = Math.floor((differenceInMs / (1000 * 60)) % 60);
  if (differenceInMinutes > 0) {
    minutes = `${differenceInMinutes}m `;
  }
  return `${differenceInHours}h ${minutes}`;
}

function calculateDayDifference(departure: string | Date, arrival: string | Date) {
  const departureDate = typeof departure === "string" ? departure.split("+")[0] : departure;
  const arrivalDate = typeof arrival === "string" ? arrival.split("+")[0] : arrival;
  return differenceInCalendarDays(new Date(arrivalDate), new Date(departureDate));
}

function createFlightDetailRequestBody(
  data: FlightDetailAPIRequestBodyType,
  appCode: string,
  promotionCode?: string,
  multiCityIndex?: number,
  queryDate?: boolean
) {
  switch (data.tripType) {
    case ETripType.oneWay:
      return {
        application_code: appCode,
        flights: [
          {
            origin: data.firstOrigin,
            destination: data.firstDestination,
            begin_date: formateDateFormat2(data.firstDate),
          },
        ],
        currency_code: data.bookingCurrency,
        promotion_code: promotionCode ?? "",
        passengers: data.passengers,
      };
    case ETripType.roundTrip:
      return {
        application_code: appCode,
        flights: [
          {
            origin: data.firstOrigin,
            destination: data.firstDestination,
            begin_date: formateDateFormat2(data.firstDate),
            end_date: formateDateFormat2(data.secondDate),
          },
        ],
        currency_code: data.bookingCurrency,
        promotion_code: promotionCode ?? "",
        passengers: data.passengers,
      };
    case ETripType.multiCity: {
      const temp: APIRequestBodyLowFareAndFlightSearch = {
        application_code: appCode,
        currency_code: data.bookingCurrency,
        promotion_code: promotionCode ?? "",
        passengers: data.passengers,
        flights: [],
      };

      // if (multiCityIndex === 0) {
      //   temp.flights.push({
      //     origin: data.firstOrigin,
      //     destination: data.firstDestination,
      //     begin_date: formateDateFormat2(data.firstDate),
      //   });
      // } else if (multiCityIndex === 1) {
      //   temp.flights.push({
      //     origin: data.secondOrigin,
      //     destination: data.secondDestination,
      //     begin_date: formateDateFormat2(queryDate ? data.firstDate : data.secondDate),
      //   });
      // } else {
      temp.flights = [
        {
          origin: data.firstOrigin,
          destination: data.firstDestination,
          begin_date: formateDateFormat2(data.firstDate),
        },
        {
          origin: data.secondOrigin,
          destination: data.secondDestination,
          begin_date: formateDateFormat2(data.secondDate),
        },
      ].sort((a, b) => new Date(a.begin_date).getTime() - new Date(b.begin_date).getTime());
      // }
      return temp;
    }
  }
}

function expandLowFareOptions(
  lowFareOptions: LowFareList,
  newOptions: LowFareList,
  setOptions: React.Dispatch<React.SetStateAction<LowFareList>>
) {
  const newDataFiltered = newOptions.filter(
    (newOptions) => !lowFareOptions.some((option) => option.date === newOptions.date)
  );
  // console.log("New Data Filtered", newDataFiltered, lowFareOptions, newOptions);
  if (
    lowFareOptions.length > 0 &&
    newDataFiltered.length > 0 &&
    isBefore(newDataFiltered[newDataFiltered.length - 1].date, lowFareOptions[0].date)
  ) {
    setOptions([...newDataFiltered, ...lowFareOptions]);
    return [...newDataFiltered, ...lowFareOptions];
  } else {
    setOptions(lowFareOptions.concat(newDataFiltered));
    return lowFareOptions.concat(newDataFiltered);
  }
}

function constructSSRInfoBody(data: TripDetail, application_code: string) {
  const ssrJourney = data.journeys.map((journey) => {
    return {
      journey_key: journey.journey_key,
      segments: journey.segments.map((segment) => {
        return {
          segment_key: segment.segment_key,
          leg_key: segment.legs[0].leg_key,
        };
      }),
    };
  });
  return {
    currency_code: data.journeys[0].fare.currency_code,
    application_code: application_code,
    journeys: ssrJourney,
  };
}

function constructCreateOrderBody(
  data: TripDetail,
  passengerCount: PassengerCount,
  application_code: string,
  promotion_code?: string
): APIRequestBodyFlightSelectCreateOrder {
  const journeys = data.journeys.map((journey) => {
    return {
      journey_key: journey.journey_key,
      fare_availability_key: journey.fare.fare_availability_key,
    };
  });

  const adultCount = passengerCount.Adult;
  const childrenCount = passengerCount.Child > 0 ? passengerCount.Child : undefined;
  const infantCount = passengerCount.Infant > 0 ? passengerCount.Infant : undefined;

  const passengers: OrderPassenger = { adult_count: adultCount };

  if (childrenCount) {
    passengers.children_count = childrenCount;
  }

  if (infantCount) {
    passengers.infant_count = infantCount;
  }
  const currency_code = data.journeys[0].fare.currency_code;

  return {
    promotion_code: promotion_code,
    application_code: application_code,
    journeys: journeys,
    passengers: passengers,
    currency_code: currency_code,
  };
}

function constructDateOptionRequestBody(
  firstDate: string,
  secondDate: string = "",
  searchingDetail?: SearchingDetail,
  bookingCurrency?: string
): FlightDetailAPIRequestBodyType | undefined {
  if (!searchingDetail || !bookingCurrency) return;
  const baseBody: BaseTripRequestBody = {
    firstOrigin: searchingDetail.trips[0].from,
    firstDestination: searchingDetail.trips[0].to,
    firstDate,
    bookingCurrency: bookingCurrency,
    passengers: {
      adult_count: searchingDetail.passenger_count.Adult,
      infant_count: searchingDetail.passenger_count.Infant,
      children_count: searchingDetail.passenger_count.Child,
    },
  };
  switch (searchingDetail.tripType) {
    case ETripType.oneWay:
      return { ...baseBody, tripType: ETripType.oneWay };
    case ETripType.roundTrip:
      return {
        ...baseBody,
        secondDate,
        tripType: ETripType.roundTrip,
      };

    case ETripType.multiCity:
      return {
        ...baseBody,
        secondDate,
        secondOrigin: searchingDetail.trips[1]?.from,
        secondDestination: searchingDetail.trips[1]?.to,
        tripType: ETripType.multiCity,
      };

    default:
      return;
  }
}

export {
  calculateDayDifference,
  calculateTimeDifference,
  constructCreateOrderBody,
  constructDateOptionRequestBody,
  constructSSRInfoBody,
  createFlightDetailRequestBody,
  expandLowFareOptions,
  formateDate,
  formateDateFormat2,
  formateTime,
};
