import { ArItem, ArItems, TripDetail } from "@hkexpressairwayslimited/ui";
import { add } from "lib/common/helper";
import { ManageMyBookingTripDetail } from "lib/features/manage-my-booking/my-trips/definition";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { selectTripDetail } from "store/sessionStorage/slices/flightBookingSlice";
import { EArItemType } from "ui/features/flight-booking/add-extras";
import { calcAllPickedArItems } from "./calcAllPickedArItems";

export function useTripCost(str?: EArItemType | ((arItem: ArItem) => boolean), tripArItems?: ArItems[]) {
  const storedArItem = useSelector(selectTripDetail)?.arItems;
  return useMemo(() => {
    return calcAllPickedArItems(
      tripArItems ? tripArItems : storedArItem ? [storedArItem] : [],
      str ? (typeof str === "string" ? (e: ArItem) => e.type === str : str) : undefined,
      true
    );
  }, [tripArItems, storedArItem, str]);
}

export function getStoredTripArItems(trip?: ManageMyBookingTripDetail | TripDetail) {
  return trip?.arItems ? [trip?.arItems] : undefined;
}
export function getTripExtrasCost(trip?: ManageMyBookingTripDetail | TripDetail) {
  const tripArCost = trip?.arItems.purchased.reduce((a, c) => (c.price ? add(a, c.price) : a), 0) ?? 0;
  return tripArCost;
}

export function getAllPurchasedCost(trip?: ManageMyBookingTripDetail | TripDetail) {
  const tripArCost =
    trip?.arItems.purchased
      .filter((e) => e.type === EArItemType.SIM_CARD)
      .reduce((a, c) => (c.price ? add(a, c.price) : a), 0) ?? 0;
  const journeyArCost =
    trip?.journeys.reduce(
      (a, c) =>
        add(
          c.arItems.purchased.reduce((a1, c1) => (c1.price ? add(a1, c1.price) : a1), 0),
          a
        ),

      0
    ) ?? 0;
  const segmentArCost =
    trip?.journeys.reduce(
      (a, c) =>
        add(
          c.segments.reduce(
            (a1, c1) =>
              add(
                c1.arItems.purchased.reduce((a2, c2) => (c2.price ? add(a2, c2.price) : a2), 0),
                a1
              ),
            0
          ),
          a
        ),
      0
    ) ?? 0;

  return add(tripArCost, journeyArCost, segmentArCost);
}
