import { ArItems, EFont, ESpacing, Font, IBEPax, MealIcon } from "@hkexpressairwayslimited/ui";
import { Stack } from "@mui/material";
import { useTransContent } from "modules/common/trans-content/transContent";
import { Drawer } from "../../Drawer";
import JourneyMeals from "./JourneyMeals";
export type MealDrawerProps = {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  confirmLabel?: string | JSX.Element | JSX.Element[];
  totalCost: string | JSX.Element;
  segmentsMealArItems: (ArItems & { id: string })[] | undefined;
  passengers?: IBEPax[];
  onPaxSelectMeal: (paxId: string, mealCode: string, amount: number) => void;
  switcher?: JSX.Element;
  autoClose?: boolean;
  active?: string;
};
export function MealDrawer({
  open,
  onClose,
  onConfirm,
  totalCost,
  segmentsMealArItems,
  passengers,
  onPaxSelectMeal,
  switcher,
  confirmLabel,
  autoClose = true,
  active,
}: MealDrawerProps) {
  const { t } = useTransContent();

  return (
    <Drawer
      title={t("web.addExtras.meal.title")}
      open={open}
      onClose={onClose}
      avatar={<MealIcon />}
      totalCost={totalCost}
      onConfirm={onConfirm}
      autoClose={autoClose}
      confirmLabel={t("web.manageMyBooking.myTrips.addItems.btn")}
      crossIconClose={true}
    >
      <Stack spacing={ESpacing._sm} width={"100%"}>
        <Font>{t("web.addExtras.meal.drawer.preOrder")}</Font>
        {switcher}
        {segmentsMealArItems?.map((e) => (
          <JourneyMeals
            key={e.id}
            arItems={e}
            onPaxSelectMeal={onPaxSelectMeal}
            passengers={passengers}
            active={active === e.id}
          />
        ))}

        <ul style={{ marginTop: "0" }}>
          <li>
            <Font variant={EFont.p3}>{t("web.addExtras.meal.drawer.content1")}</Font>
          </li>
          <li>
            <Font variant={EFont.p3}>{t("web.addExtras.meal.drawer.content2")}</Font>
          </li>
          <li>
            <Font variant={EFont.p3}>{t("web.addExtras.meal.drawer.content3")}</Font>
          </li>
          <li>
            <Font variant={EFont.p3}>{t("web.addExtras.meal.drawer.content4")}</Font>
          </li>
        </ul>
      </Stack>
    </Drawer>
  );
}
