// const apiBrowserInfoLogger = debug("api:browser:console:info");
// const apiBrowserErrorLogger = debug("api:browser:console:error");
// const apiBrowserWarnLogger = debug("api:browser:console:warn");
// const apiBrowserTraceIdLogger = debug("api:browser:modal:traceId");

// const apiServerInfoLogger = debug("api:server:console:info");
// const apiServerErrorLogger = debug("api:server:console:error");
// const apiServerWarnLogger = debug("api:server:console:warn");

// let apiInfoLogger = apiServerInfoLogger;
// let apiErrorLogger = apiServerErrorLogger;
// let apiWarnLogger = apiServerWarnLogger;

// if (typeof window !== "undefined") {
//   apiBrowserInfoLogger.log = window.console.info.bind(window.console);
//   apiBrowserErrorLogger.log = window.console.error.bind(window.console);
//   apiBrowserWarnLogger.log = window.console.warn.bind(window.console);
//   apiBrowserTraceIdLogger.log = window.console.error.bind(window.console);
//   apiInfoLogger = apiBrowserInfoLogger;
//   apiErrorLogger = apiBrowserErrorLogger;
//   apiWarnLogger = apiBrowserWarnLogger;
// }

const apiBrowserInfoLogger = console.info;
const apiBrowserErrorLogger = console.error;
const apiBrowserWarnLogger = console.warn;
const apiBrowserTraceIdLogger = console.log;

const apiServerInfoLogger = console.info;
const apiServerErrorLogger = console.error;
const apiServerWarnLogger = console.warn;

const apiInfoLogger = apiServerInfoLogger;
const apiErrorLogger = apiServerErrorLogger;
const apiWarnLogger = apiServerWarnLogger;

// if (typeof window !== "undefined") {
//   apiBrowserInfoLogger.log = window.console.info.bind(window.console);
//   apiBrowserErrorLogger.log = window.console.error.bind(window.console);
//   apiBrowserWarnLogger.log = window.console.warn.bind(window.console);
//   apiBrowserTraceIdLogger.log = window.console.error.bind(window.console);
//   apiInfoLogger = apiBrowserInfoLogger;
//   apiErrorLogger = apiBrowserErrorLogger;
//   apiWarnLogger = apiBrowserWarnLogger;
// }

export { apiBrowserTraceIdLogger, apiErrorLogger, apiInfoLogger, apiWarnLogger };
