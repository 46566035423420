import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { MetaData } from "lib/features/flight-book/booking/service";
import { RootState } from "store";

export type GlobalMetaData = {
  [key: string]: any;
};

export type TraceIds = {
  XB3Spanid: string;
  XB3Traceid: string;
};

const initialState: GlobalMetaData = {};

export const globalMetaData = createSlice({
  name: "globalMetaData",
  initialState,
  reducers: {
    storeGlobalMetaData: (state, action: PayloadAction<MetaData<any>[]>) => {
      action.payload.forEach((e) => {
        state[e.metadata_key] = e.metadata_value;
      });
    },
    storeGlobalTraceIds: (state, action: PayloadAction<Partial<TraceIds>>) => {
      Object.keys(action.payload).forEach((e) => {
        state[e] = action.payload[e as keyof TraceIds];
      });
    },
  },
  selectors: {
    selectGlobalMetaData: (state): GlobalMetaData => state,
  },
});

export const { storeGlobalMetaData, storeGlobalTraceIds } = globalMetaData.actions;
export default globalMetaData.reducer;
export const { selectGlobalMetaData } = globalMetaData.getSelectors((state: RootState) => {
  return state.session.globalMetaData;
});
