import {
  ArItem,
  ArItems,
  Button,
  ButtonVariant,
  Card,
  CardContent,
  CardHeader,
  EFont,
  EShadows,
  ESpacing,
  ETripType,
  Font,
  IBEPax,
  Image,
  Journey,
  MealIcon,
  StatusWarningIcon,
} from "@hkexpressairwayslimited/ui";
import { Box, Stack, SvgIcon } from "@mui/material";
import { flatMap, unionBy } from "lodash";
import { useTransContent } from "modules/common/trans-content/transContent";
import { calcAllPickedArItems } from "modules/features/flightBooking/calculateCost/calcAllPickedArItems";
import { getStoredSegmentsArItems, useSegmentCost } from "modules/features/flightBooking/calculateCost/useSegmentsCost";
import { SelectedArItems } from "modules/features/flightBooking/reducers";
import { useCallback, useMemo, useState } from "react";
// import {
//   selectAddExtrasPageMetaData,
//   updateAddExtrasPageMetaData,
// } from "store/sessionStorage/slices/flightBookingSlice";
import { useCurrency } from "modules/global/currency/useCurrency";
import { EArItemType, OnMealCloseData, OnMealSelectedData } from "..";
import PopUpModal from "../../flight-select/pop-up-modal/PopUpModal";
import { InvalidMessagePopUp } from "../InvalidMessagePopUp";
import { MealDrawer } from "./MealDrawer";
import { MealSummary } from "./MealSummary";
import SegmentsSwitcher, { SegmentsSwitcherClickData } from "./SegmentsSwitcher";

export type MealSectionProps = {
  journeys: Journey[];
  onConfirm: (b: boolean, cleanAction?: boolean) => void;
  onOpenMealDrawer?: () => void;
  error: boolean;
  passengers: IBEPax[];
  tripType: ETripType;
  onMealSelected: (data: OnMealSelectedData) => void;
  onMealClose?: (data: OnMealCloseData) => void;
  mealConfirmed: boolean;
  selectedArItem: SelectedArItems[];
  tripId: string;
};
export function MealSection({
  journeys,
  onConfirm,
  error,
  passengers,
  tripType,
  onMealSelected,
  onMealClose,
  tripId,
  selectedArItem = [],
  mealConfirmed,
  onOpenMealDrawer,
}: MealSectionProps) {
  const [open, setOpen] = useState(false);
  const mealConst = useSegmentCost(EArItemType.MEAL, getStoredSegmentsArItems(journeys));
  const mealArItem = useMemo(() => {
    return journeys.reduce<ArItem[]>((acc, curt) => {
      return unionBy(
        [
          ...acc,
          ...curt.segments.reduce<ArItem[]>((a, c) => {
            return [...a, ...c.arItems.available.filter((e) => e.type === EArItemType.MEAL)];
          }, []),
        ],
        (e) => e.code
      );
    }, []);
  }, [journeys]);
  const LowestPrice = useMemo(() => {
    let LowestPrice = Infinity;
    mealArItem.forEach((e) => {
      if (e.price && e.price < LowestPrice) {
        LowestPrice = e.price;
      }
    });
    return LowestPrice;
  }, [mealArItem]);
  // const metaData = useSelector(selectAddExtrasPageMetaData)?.confirmedItems;
  const [confirmed, setConfirmed] = useState(mealConfirmed);
  const selectedMealCost = useMemo(
    () =>
      calcAllPickedArItems(
        selectedArItem.reduce((a: ArItems[], c) => {
          a.push({
            available:
              journeys.find((e) => e.journey_key === c.journeyKey)?.segments.find((e) => e.segment_key === c.segmentKey)
                ?.arItems.available ?? [],
            selected: c.arItems ?? [],
            purchased: [],
          });
          return a;
        }, []),
        undefined,
        true
      ),
    [journeys, selectedArItem]
  );
  function handleMealConfirm() {
    if (!confirmed && selectedMealCost === 0) {
      setOpen(true);
    } else {
      setSelectionOpen(false);
      onConfirm && onConfirm(true);
      // dispatch(
      //   updateAddExtrasPageMetaData({
      //     confirmedItems: {
      //       [EArItemType.BAGGAGE]: true,
      //     },
      //   })
      // );
      setConfirmed(true);
    }
  }
  // function handleMealDrawerConfirm(){
  //   setSelectionOpen(false)
  // }
  const [selectionOpen, setSelectionOpen] = useState(false);
  const [noMealhandleing, setNoMealhandleing] = useState(false);
  const handleNoMeal = useCallback(() => {
    setNoMealhandleing(true);
    setOpen(true);
  }, []);

  const [selectedJourneyKey, setSelectedJourneyKey] = useState(journeys[0].journey_key);
  const [selectedSegmentKey, setSelectedSelectedSegment] = useState(journeys[0].segments[0].segment_key);
  const segmentsMealArItems = useMemo(() => {
    return flatMap(journeys, (e) =>
      flatMap(e.segments, (n) => ({
        id: n.segment_key,
        available: n.arItems.available.filter((e) => e.type === EArItemType.MEAL),
        selected:
          selectedArItem.find(
            (e) => e.tripId === tripId && e.journeyKey === selectedJourneyKey && e.segmentKey === selectedSegmentKey
          )?.arItems ?? [],
        purchased: n.arItems.purchased.filter((e) => e.type === EArItemType.MEAL),
      }))
    );
  }, [journeys, selectedArItem, selectedJourneyKey, selectedSegmentKey, tripId]);
  const handlePaxSelectMeal = useCallback(
    (paxId: string, mealCode: string, amount = 0) => {
      // if (paxId && mealCode) {
      //   dispatch(updatePaxMeal({ selectedJourneyKey, selectedSegmentKey, paxId, mealCode, amount }));
      // }
      onMealSelected({ tripId, selectedJourneyKey, selectedSegmentKey, paxId, mealCode, amount });
    },
    [onMealSelected, selectedJourneyKey, selectedSegmentKey, tripId]
  );
  const handleSegmentSwitch = (data: SegmentsSwitcherClickData) => {
    if (data.journey_key) {
      setSelectedJourneyKey(data.journey_key);
      setSelectedSelectedSegment(data.segment_key);
    }
  };
  const { t } = useTransContent();
  const { P } = useCurrency();
  return (
    <>
      <Card size='l' sx={{ my: ESpacing._md }}>
        <CardHeader
          avatar={
            <SvgIcon sx={{ color: "purple.default", width: "32px", height: "32px" }}>
              <MealIcon />
            </SvgIcon>
          }
          title={
            <Font variant={EFont.h3} fontWeight='fontWeightBold' mt='10px'>
              {t("web.addExtras.meal.title")}
            </Font>
          }
        ></CardHeader>
        <CardContent>
          {confirmed ? (
            <MealSummary passengers={passengers} journeys={journeys} tripType={tripType} />
          ) : (
            <>
              <Font
                id='meal_section_title'
                sx={{
                  mb: ESpacing._sm,
                }}
              >
                {t("web.addExtras.meal.preOrder")}
              </Font>
              <Stack direction={["column", "column", "row"]} columnGap={ESpacing._sm} rowGap={ESpacing._sm}>
                <Card
                  aria-labelledby='meal_section_title'
                  sx={{
                    flex: 1,
                    ":focus-visible": {
                      boxShadow: EShadows.focus,
                      outline: "none",
                    },
                  }}
                  onClick={() => {
                    setSelectionOpen(true);
                  }}
                  overflow={true}
                  tabIndex={0}
                >
                  <CardContent>
                    <Stack
                      direction={["column", "row"]}
                      justifyContent='space-between'
                      sx={{
                        mb: ESpacing._2xs,
                      }}
                    >
                      <Font>{t("web.addExtras.meal.order")}</Font>
                      {!!mealArItem.length && (
                        <Stack direction='row' alignItems='center' columnGap={ESpacing._3xs}>
                          <Font variant={EFont.p3}>{t("web.addExtras.meal.from")}</Font>
                          <Font color='purple.default' fontWeight='fontWeightBold'>
                            {P(LowestPrice)}
                          </Font>
                        </Stack>
                      )}
                    </Stack>
                    <Stack
                      direction='row'
                      spacing={ESpacing._3xs}
                      // justifyContent={"space-between"}

                      sx={{
                        overflowX: "auto",
                      }}
                    >
                      {mealArItem.length ? (
                        mealArItem.map((e) => (
                          <Image
                            onClick={() => {
                              onOpenMealDrawer && onOpenMealDrawer();
                            }}
                            key={e.code}
                            // If there is no valid mirror path or the path is empty, the mirror path is defaulted, otherwise an invalid path error is reported
                            src={
                              (t(`${e.code}.imageUrl`) === `${e.code}.imageUrl`
                                ? (t("web.default.image") as string)
                                : t(`${e.code}.imageUrl`)) as string
                            }
                            width='120'
                            height='120'
                            alt={e.code}
                          />
                        ))
                      ) : (
                        <Font fontWeight='fontWeightBold'>{t("web.addExtras.meal.noMeal")}</Font>
                      )}
                    </Stack>
                  </CardContent>
                </Card>
                <Card
                  sx={{
                    flex: ["1", "1", "0 0 auto"],
                    cursor: "pointer",
                    width: ["100%", "100%", "224px"],
                    ":focus-visible": {
                      boxShadow: EShadows.focus,
                      outline: "none",
                    },
                  }}
                  onClick={handleNoMeal}
                  tabIndex={0}
                >
                  <CardContent>
                    <Stack direction={["row", "row", "column"]} alignItems='center' justifyContent='space-between'>
                      <Font
                        sx={{
                          display: ["28px", "28px", "auto"],
                          textAlign: "center",
                          mb: [0, 0, ESpacing._s],
                        }}
                      >
                        {t("web.addExtras.meal.noMeal")}
                      </Font>
                      <Stack alignItems='center' justifyContent='center'>
                        <SvgIcon sx={{ color: "purple.default", fontSize: ["28px", "28px", "64px"] }}>
                          <MealIcon />
                        </SvgIcon>
                      </Stack>
                    </Stack>
                  </CardContent>
                </Card>
              </Stack>
            </>
          )}
          <Box
            sx={{
              mt: ESpacing._sm,
            }}
          >
            <Card withBGC borderless={!error} bodC={error ? "error" : "default"}>
              <CardContent>
                <Stack
                  sx={{
                    flexDirection: "row",
                  }}
                  justifyContent='space-between'
                >
                  <Stack
                    direction={["column", "column", "row"]}
                    alignItems={["flex-start", "flex-start", "center"]}
                    columnGap={ESpacing._2xs}
                  >
                    <Font>{t("web.addExtras.meal.subtotal")}</Font>
                    <Font variant={EFont.h3} fontWeight='fontWeightBold'>
                      {P(mealConst, undefined, false, 0, true)}
                    </Font>
                  </Stack>
                  <Button
                    style={{ height: "40px", alignSelf: "center" }}
                    onClick={() => {
                      if (confirmed) {
                        setConfirmed(false);
                        onConfirm && onConfirm(false);
                      } else {
                        handleMealConfirm();
                      }
                    }}
                    variant={ButtonVariant.Secondary}
                  >
                    <Font>
                      {confirmed ? t("web.addExtras.meal.button.edit") : t("web.addExtras.meal.button.confirm")}
                    </Font>
                  </Button>
                </Stack>
              </CardContent>
            </Card>
            {error && (
              <Stack
                mt={ESpacing._xs}
                spacing={ESpacing._2xs}
                justifyItems='center'
                direction='row'
                sx={{ color: "critical.default" }}
              >
                <SvgIcon>
                  <StatusWarningIcon />
                </SvgIcon>
                <Font>{t("web.addExtras.meal.confirm")}</Font>
              </Stack>
            )}
          </Box>
        </CardContent>
      </Card>
      <MealDrawer
        passengers={passengers}
        open={selectionOpen}
        onClose={() => {
          setSelectionOpen(false);
          onMealClose &&
            onMealClose({
              tripId: tripId,
              journeyKey: selectedJourneyKey,
              segmentKey: selectedSegmentKey,
            });
        }}
        onConfirm={handleMealConfirm}
        totalCost={P(selectedMealCost, undefined, false, 0, true)}
        segmentsMealArItems={segmentsMealArItems}
        active={selectedSegmentKey}
        onPaxSelectMeal={handlePaxSelectMeal}
        switcher={
          <SegmentsSwitcher
            selectedArItem={selectedArItem}
            journeys={journeys}
            onClick={handleSegmentSwitch}
            data={{ segment_key: selectedSegmentKey, journey_key: selectedJourneyKey, tripId }}
          />
        }
      />
      <PopUpModal maxWidth='xs' open={open} setOpen={setOpen}>
        <InvalidMessagePopUp
          onConfirm={() => {
            setOpen(false);
            setSelectionOpen(false);
            onConfirm && onConfirm(true, noMealhandleing);
            // dispatch(
            //   updateAddExtrasPageMetaData({
            //     confirmedItems: {
            //       [EArItemType.BAGGAGE]: true,
            //     },
            //   })
            // );
            setConfirmed(true);
            setNoMealhandleing(false);
          }}
          onCancel={() => {
            setOpen(false);
            setSelectionOpen(true);
            onOpenMealDrawer && onOpenMealDrawer();
          }}
          mealError={true}
        />
      </PopUpModal>
    </>
  );
}
