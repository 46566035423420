import { getApi, memberAuthEndPoints } from "api";
import { getLangLocale } from "modules/common/common/utils/i18n-utils";
import { GeneralProfile } from "./types";

export type FetchGeneralProfileRes = {
  metadata: {
    app_version: string;
    status_code: number;
    trace_id: string;
  };
} & GeneralProfile;

export const fetchGeneralProfile = async () =>
  getApi(
    `${memberAuthEndPoints.getProfile}?${new URLSearchParams({ lang: getLangLocale() }).toString()}`
  ) as Promise<FetchGeneralProfileRes>;

export { decodeTokenProfile } from "@hkexpressairwayslimited/ui";
