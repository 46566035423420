import { EFont, ESpacing, FieldInput, Font, Radio, RadioGroup } from "@hkexpressairwayslimited/ui";
import { FormControlLabel, Stack } from "@mui/material";
import { useTransContent } from "modules/common/trans-content/transContent";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { selectCurrencyDetail } from "store/sessionStorage/slices/currency";
export type DdcOptionItem = {
  price: string;
  rateDesc?: string;
  value: string;
  data: {
    rate: number;
    amount: number;
    currency: string;
  };
};
// const option: DdcOptionItem[] = [
//   { price: "USD 781.3", rateDesc: "1 HKD = 0.12 USD", value: '0' },
//   { price: "HKD 781.3", value: '1' },
// ];
export default function Dcc({ options, onChange }: { options?: DdcOptionItem[]; onChange?: (value: string) => void }) {
  const { t } = useTransContent();
  return (
    <Stack
      sx={{
        width: "100%",
      }}
    >
      <Font variant={EFont.h3} sx={{ minHeight: "50px", lineHeight: "50px", color: "primary.main" }}>
        {t("web.flightBook.payment.doc.title")}
      </Font>
      <Font>{t("web.flightBook.payment.doc.desc")}</Font>
      {options && (
        <Stack
          direction={["column", "column", "row"]}
          sx={{
            marginTop: ESpacing._sm,
            width: ["100%", "100%", "90%"],
            justifyContent: "space-between",
          }}
        >
          <FieldInput
            name='dccOption'
            fullWidth
            onValueChange={(ev: any, value: string) => {
              onChange && onChange(value);
            }}
          >
            <RadioGroup
              row
              sx={{
                justifyContent: "space-between",
                alignItems: "flex-end",
              }}
              name='dccOption'
            >
              {options.map((item) => (
                <FormControlLabel
                  key={item.value?.toString()}
                  value={item.value}
                  control={<Radio />}
                  label={<DCCOption {...item} />}
                />
              ))}
            </RadioGroup>
          </FieldInput>
        </Stack>
      )}
    </Stack>
  );
}

function DCCOption({ price, rateDesc }: DdcOptionItem) {
  const { t } = useTransContent();
  return (
    <Stack
      sx={{
        maxWidth: { lg: "400px", md: "240px", sm: "310px", xs: "100%" },
        marginTop: rateDesc ? { lg: "4px", md: "-22px" } : 0,
        marginBottom: [ESpacing._s, ESpacing._s, 0],
      }}
    >
      <Font variant={EFont.p2}>
        {rateDesc
          ? t("web.flightBook.payment.currency.option.text1")
          : t("web.flightBook.payment.currency.option.text2")}
      </Font>
      <Font variant={EFont.p1} color='primary.main'>
        {price}
      </Font>
      <Font>
        {rateDesc
          ? t("web.flightBook.payment.currency.option.desc1")
          : t("web.flightBook.payment.currency.option.desc2")}
      </Font>
      {rateDesc && <Font>{rateDesc}</Font>}
    </Stack>
  );
}

export function useDcc(disabled = false) {
  const { selectedCurrency, bookingCurrency } = useSelector(selectCurrencyDetail);
  return useMemo(() => {
    return disabled ? false : selectedCurrency === bookingCurrency || bookingCurrency === "AMX";
  }, [bookingCurrency, disabled, selectedCurrency]);
}

export function useMcp(disabled = false) {
  const { selectedCurrency, bookingCurrency } = useSelector(selectCurrencyDetail);
  return useMemo(() => {
    return disabled
      ? false
      : bookingCurrency !== "AMX" && selectedCurrency !== "" && selectedCurrency !== bookingCurrency;
  }, [bookingCurrency, disabled, selectedCurrency]);
}
