import { Box } from "@hkexpressairwayslimited/ui";
import { showLoadingBackdrop } from "modules/common/loading-backdrop/actions/LoadingBackdropAction";
import { RouterInstance } from "router/router-utils";
import store from "store";
import { resetFlightBooking, resetStep4 } from "store/sessionStorage/slices/flightBookingSlice";
import { ErrorMapping, ErrorMessageDetail, ErrorType, responseApiError } from "./definition";
function step0() {
  store.dispatch(showLoadingBackdrop());
  store.dispatch(resetFlightBooking());
  RouterInstance.replace("/:lang");
}

export const ErrorCodeMapping: ErrorMapping = {
  // ErrorType.Confirm
  PROMOTION_CODE_IS_INVALID: {
    type: ErrorType.C,
    title: "PROMOTION_CODE_IS_INVALID.title",
    desc: "PROMOTION_CODE_IS_INVALID.desc",
    ctaText: "PROMOTION_CODE_IS_INVALID.ctaText",
    image: "PROMOTION_CODE_IS_INVALID.image",
    onConfirm: step0,
  },
  PROMOTION_CODE_IS_NON_EXISTENT: {
    type: ErrorType.C,
    title: "PROMOTION_CODE_IS_NON_EXISTENT.title",
    desc: "PROMOTION_CODE_IS_NON_EXISTENT.desc",
    ctaText: "PROMOTION_CODE_IS_NON_EXISTENT.ctaText",
    image: "PROMOTION_CODE_IS_NON_EXISTENT.image",
    onConfirm: step0,
  },
  PROMOTION_CODE_IS_OUTDATED: {
    type: ErrorType.C,
    title: "PROMOTION_CODE_IS_OUTDATED.title",
    desc: "PROMOTION_CODE_IS_OUTDATED.desc",
    ctaText: "PROMOTION_CODE_IS_OUTDATED.ctaText",
    image: "PROMOTION_CODE_IS_OUTDATED.image",
    onConfirm: step0,
  },
  PROMOTION_CODE_IS_SOLD_OUT: {
    type: ErrorType.C,
    title: "PROMOTION_CODE_IS_SOLD_OUT.title",
    desc: "PROMOTION_CODE_IS_SOLD_OUT.desc",
    ctaText: "PROMOTION_CODE_IS_SOLD_OUT.ctaText",
    image: "PROMOTION_CODE_IS_SOLD_OUT.image",
    onConfirm: step0,
  },
  // ErrorType.Detail
  "No booking in state": {
    type: ErrorType.M,
    title: "No_booking_in_state.title",
    desc: "No_booking_in_state.desc",
    onClose: step0,
  },
  PAY_PAYMENT_LIMIT_EXCEEDED: {
    type: ErrorType.M,
    title: "PAY_PAYMENT_LIMIT_EXCEEDED.title",
    desc: "PAY_PAYMENT_LIMIT_EXCEEDED.desc",
    onClose: step0,
  },
  PAY_PAYMENT_EXISTS: {
    type: ErrorType.M,
    title: "PAY_PAYMENT_EXISTS.title",
    desc: "PAY_PAYMENT_EXISTS.desc",
    onClose: step0,
  },
  SELL_TRIP_FAILURE: {
    type: ErrorType.D,
    title: "ORDER_SVC_SELL_TRIP_FAILURE.title",
    desc: "ORDER_SVC_SELL_TRIP_FAILURE.desc",
    ctaText: "ORDER_SVC_SELL_TRIP_FAILURE.ctaText",
    // onClose: () => {},
    onConfirm: () => {
      // window.location.reload();
    },
    detailsElm: (details: any[]) => {
      return details?.map((e) => <Box key={e.errorCode}>{e.desc}</Box>);
    },
  },
  FLIGHT_TIME_CHANGED: {
    type: ErrorType.D,
    title: "ORDER_SVC_FLIGHT_TIME_CHANGED.title",
    desc: "ORDER_SVC_FLIGHT_TIME_CHANGED.desc",
    ctaText: "ORDER_SVC_FLIGHT_TIME_CHANGED.ctaText",
    onConfirm: () => {
      //dispatch()
      RouterInstance.replace("/:lang/flight-booking/select");
    },
    detailsElm: (details: any[]) => {
      return details.map((e) => <Box key={e.errorCode}>{e.desc}</Box>);
    },
  },
  JOURNEY_NOT_FOUND: {
    type: ErrorType.D,
    title: "ORDER_SVC_JOURNEY_NOT_FOUND.title",
    desc: "ORDER_SVC_JOURNEY_NOT_FOUND.desc",
    ctaText: "ORDER_SVC_JOURNEY_NOT_FOUND.ctaText",
    onConfirm: () => {
      //dispatch()
      RouterInstance.replace("/:lang/flight-booking/select");
    },
    detailsElm: (details: any[]) => {
      return details.map((e) => <Box key={e.errorCode}>{e.desc}</Box>);
    },
  },
  JOURNEY_TOTAL_AMOUNT_NOT_MATCH: {
    type: ErrorType.D,
    title: "ORDER_SVC_JOURNEY_TOTAL_AMOUNT_NOT_MATCH.title",
    desc: "ORDER_SVC_JOURNEY_TOTAL_AMOUNT_NOT_MATCH.desc",
    ctaText: "ORDER_SVC_JOURNEY_TOTAL_AMOUNT_NOT_MATCH.ctaText",
    onConfirm: () => {
      //dispatch()
      RouterInstance.replace("/:lang/flight-booking/select");
    },
    detailsElm: (details?: any[]) => {
      return details && details.map((e) => <Box key={e.errorCode}>{e.desc}</Box>);
    },
  },
  SEAT_AMOUNT_NOT_MATCH: {
    type: ErrorType.D,
    title: "ORDER_SVC_SEAT_AMOUNT_NOT_MATCH.title",
    desc: "ORDER_SVC_SEAT_AMOUNT_NOT_MATCH.desc",
    ctaText: "ORDER_SVC_SEAT_AMOUNT_NOT_MATCH.ctaText",
    onConfirm: () => {
      //dispatch()
      store.dispatch(resetStep4());
      RouterInstance.replace("/:lang/flight-booking/add-extras");
    },
    detailsElm: (details: any[]) => {
      return details.map((e) => <Box key={e.errorCode}>{e.desc}</Box>);
    },
  },
  ORDER_ADD_SEAT_FAIL: {
    type: ErrorType.D,
    title: "ORDER_SVC_ORDER_ADD_SEAT_FAIL.title",
    desc: "ORDER_SVC_ORDER_ADD_SEAT_FAIL.desc",
    ctaText: "ORDER_SVC_ORDER_ADD_SEAT_FAIL.ctaText",
    onConfirm: () => {
      //dispatch()
      store.dispatch(resetStep4());
      RouterInstance.replace("/:lang/flight-booking/add-extras");
    },
    detailsElm: (details: any[]) => {
      return details.map((e) => <Box key={e.errorCode}>{e.desc}</Box>);
    },
  },

  // ErrorType.Confirm
  SSR_AMOUNT_NOT_MATCH: {
    type: ErrorType.C,
    title: "ORDER_SVC_SSR_AMOUNT_NOT_MATCH.title",
    desc: "ORDER_SVC_SSR_AMOUNT_NOT_MATCH.desc",
    ctaText: "ORDER_SVC_SSR_AMOUNT_NOT_MATCH.ctaText",
    image: "ORDER_SVC_SSR_AMOUNT_NOT_MATCH.image",
    onConfirm: () => {
      //dispatch()
      store.dispatch(resetStep4());
      RouterInstance.replace("/:lang/flight-booking/add-extras");
    },
  },
  ORDER_ADD_SSRS_FAIL: {
    type: ErrorType.C,
    title: "ORDER_SVC_ORDER_ADD_SSRS_FAIL.tilte",
    desc: "ORDER_SVC_ORDER_ADD_SSRS_FAIL.desc",
    ctaText: "ORDER_SVC_ORDER_ADD_SSRS_FAIL.ctaText",
    image: "ORDER_SVC_ORDER_ADD_SSRS_FAIL.image",
    onConfirm: () => {
      //dispatch()
      store.dispatch(resetStep4());
      RouterInstance.replace("/:lang/flight-booking/add-extras");
    },
  },
  ORDER_VALIDATION_ERROR: {
    type: ErrorType.C,
    title: "ORDER_VALIDATION_ERROR.title",
    desc: "ORDER_VALIDATION_ERROR.desc",
    ctaText: "ORDER_VALIDATION_ERROR.ctaText",
    image: "ORDER_VALIDATION_ERROR.image",

    onConfirm: step0,
  },
  ORDER_INSUFFICIENT_MILES: {
    type: ErrorType.C,
    title: "ORDER_SVC_ORDER_INSUFFICIENT_MILES.title",
    desc: "ORDER_SVC_ORDER_INSUFFICIENT_MILES.desc",
    ctaText: "ORDER_SVC_ORDER_INSUFFICIENT_MILES.ctaText",
    image: "ORDER_SVC_ORDER_INSUFFICIENT_MILES.image",
    onConfirm: step0,
  },
  INVALID_CASH_AMOUNT: {
    type: ErrorType.C,
    title: "ORDER_SVC_INVALID_CASH_AMOUNT.title",
    desc: "ORDER_SVC_INVALID_CASH_AMOUNT.desc",
    ctaText: "ORDER_SVC_INVALID_CASH_AMOUNT.ctaText",
    image: "ORDER_SVC_INVALID_CASH_AMOUNT.image",
    onConfirm: step0,
  },
  ORDER_MAXIMUM_PAYMENT_ATTEMPT_EXCEED: {
    type: ErrorType.C,
    title: "ORDER_SVC_ORDER_MAXIMUM_PAYMENT_ATTEMPT_EXCEED.title",
    desc: "ORDER_SVC_ORDER_MAXIMUM_PAYMENT_ATTEMPT_EXCEED.desc",
    ctaText: "ORDER_SVC_ORDER_MAXIMUM_PAYMENT_ATTEMPT_EXCEED.ctaText",
    image: "ORDER_SVC_ORDER_MAXIMUM_PAYMENT_ATTEMPT_EXCEED.image",
    onConfirm: step0,
  },
  INVALID_MCP_AMOUNT: {
    type: ErrorType.C,
    title: "ORDER_SVC_INVALID_MCP_AMOUNT.title",
    desc: "ORDER_SVC_INVALID_MCP_AMOUNT.desc",
    ctaText: "ORDER_SVC_INVALID_MCP_AMOUNT.ctaText",
    image: "ORDER_SVC_INVALID_MCP_AMOUNT.image",
    onConfirm: step0,
  },
  INVALID_MILES_AMOUNT: {
    type: ErrorType.C,
    title: "INVALID_MILES_AMOUNT.title",
    desc: "INVALID_MILES_AMOUNT.desc",
    ctaText: "INVALID_MILES_AMOUNT.ctaText",
    image: "INVALID_MILES_AMOUNT.image",
    onConfirm: step0,
  },

  GET_ASIA_MILES_INFO_FAIL: {
    type: ErrorType.M,
    title: "GET_ASIA_MILES_INFO_FAIL.title",
    desc: "GET_ASIA_MILES_INFO_FAIL.desc",
    onClose: step0,
  },
  // ErrorType.Message
  PAYMENT_FAILURE: {
    type: ErrorType.M,
    title: "PAYMENT_FAILURE.title",
    desc: "PAYMENT_FAILURE.desc",
  },

  MAX_VOUCHER_COUNT_EXCEEDED: {
    type: ErrorType.M,
    title: "MAX_VOUCHER_COUNT_EXCEEDED.title",
    desc: "MAX_VOUCHER_COUNT_EXCEEDED.desc",
  },

  VOUCHER_ALREADY_ADDED: {
    type: ErrorType.M,
    title: "VOUCHER_ALREADY_ADDED.title",
    desc: "VOUCHER_ALREADY_ADDED.desc",
  },

  VOUCHER_GENERIC_ERROR: {
    type: ErrorType.M,
    title: "VOUCHER_GENERIC_ERROR.title",
    desc: "VOUCHER_GENERIC_ERROR.desc",
  },
  // // ErrorType.Function
  // CREATE_INSURANCE_QUOTATION_FAIL: {
  //   type: ErrorType.F,
  //   fn: () => {
  //     //dispatch()
  //   },
  // },
  // CREATE_CHOOOSE_QUOTATION_FAIL: {
  //   type: ErrorType.F,
  //   fn: () => {
  //     //dispatch()
  //   },
  // },
  // GET_ASIA_MILES_INFO_FAIL: {
  //   type: ErrorType.F,
  //   fn: () => {
  //     //dispatch()
  //   },
  // },
};

export const ErrorCodeList = Object.keys(ErrorCodeMapping);

export function getErrorDetail<T, C>(
  errorResp: responseApiError,
  details?: T[],
  data?: C
): ErrorMessageDetail<T, C> | undefined {
  if (!ErrorCodeMapping[errorResp.error_code]) return undefined;
  const errorMessage = {
    ...ErrorCodeMapping[errorResp.error_code],
    details,
    data: Object.assign({}, data, { trace_id: errorResp.metadata?.trace_id }),
  };
  if (errorMessage.type === ErrorType.F) {
    errorMessage.fn && errorMessage.fn();
    return undefined;
  }

  return errorMessage;
}
