import { ArItem, ArItems, Journey, TripDetail } from "@hkexpressairwayslimited/ui";
import { add, multiply } from "lib/common/helper";
import { MMBJourney, ManageMyBookingTripDetail } from "lib/features/manage-my-booking/my-trips/definition";
import { useMemo } from "react";
import { EArItemType } from "ui/features/flight-booking/add-extras";
import { calcAllPickedArItems } from "./calcAllPickedArItems";
export function useJourneyCost(str?: EArItemType, tripArItems?: ArItems[]) {
  // const storedJourneyArItem = getStoredJourneyArItems(useSelector(selectTripJourneys));
  return useMemo(() => {
    return calcAllPickedArItems(tripArItems ? tripArItems : [], str ? (e) => e.type === str : undefined, true);
  }, [tripArItems, str]);
}

export function getStoredJourneyArItems(journeys?: Journey[] | MMBJourney[]) {
  return journeys?.reduce<ArItems[]>((a, c) => [...a, c.arItems], []) ?? undefined;
}

export function getJourneyPurchasedExtrasCost(journey: Journey | MMBJourney) {
  const journeyArCost = journey.arItems.purchased.reduce((a, c) => (c.price ? add(a, c.price) : a), 0);
  const segmentArCost = journey.segments.reduce(
    (a, c) =>
      add(
        c.arItems.purchased.reduce((a1, c1) => (c1.price ? add(a1, c1.price) : a1), 0),
        a
      ),
    0
  );

  return add(journeyArCost, segmentArCost);
}

export function getJourneySelectedExtrasCost(
  trip: ManageMyBookingTripDetail | TripDetail | undefined,
  journey: Journey | MMBJourney | undefined,
  index: number,
  withSeatCost: boolean
) {
  const journeyArCost = journey?.arItems.selected.reduce((a, c) => {
    return add(
      a,
      multiply(
        journey.arItems.available.find((e) => e?.code === c.code)?.price ?? 0,
        c.byPax?.reduce((a2, c2) => add(a2, c2.amount), 0) ?? 0
      )
    );
  }, 0);
  const segmentArCost = journey?.segments.reduce(
    (a, c) =>
      add(
        a,
        c.arItems.selected.reduce(
          (a1, c1) =>
            add(
              a1,
              c1.price && withSeatCost
                ? c1.price
                : multiply(
                    c.arItems.available.find((e) => e.code === c1.code)?.price ?? 0,
                    c1.byPax ? c1.byPax?.reduce((a2, c2) => add(a2, c2.amount), 0) : 0
                  )
            ),
          0
        )
      ),
    0
  );

  const selectedSimCard = trip?.arItems.selected.find((e) => e.type === EArItemType.SIM_CARD);
  const availableSimCard = trip?.arItems.available.find((e) => e.type === EArItemType.SIM_CARD);

  const simCardCost =
    index === 0
      ? multiply(availableSimCard?.price ?? 0, selectedSimCard?.byPax?.reduce((a, c) => add(c.amount, a), 0) ?? 0)
      : 0;

  return add(journeyArCost ?? 0, segmentArCost ?? 0, simCardCost);
}

export function getStoredSelectedJourneyArItems(journeys?: Journey[] | MMBJourney[]) {
  return journeys?.reduce((a: ArItem[], c: Journey | MMBJourney) => a.concat([...c.arItems.selected]), []) ?? undefined;
}
