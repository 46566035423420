import { Box, Card, CardContent, EFont, EShadows, ESpacing, Font, useMediaQueries } from "@hkexpressairwayslimited/ui";
import { Stack, styled } from "@mui/material";
import { useTransContent } from "modules/common/trans-content/transContent";
import { useCurrency } from "modules/global/currency/useCurrency";
import { useParams } from "next/navigation";
import { EBaggageArCode, SelectableArItem } from "..";

const TagSmallStyle = styled(Box)(({ theme }) => ({
  backgroundColor: "#feea00",
  color: theme.palette.purple.default,
  borderTopRightRadius: "8px",
  borderBottomLeftRadius: "8px",
  padding: "4px 8px",
}));

export function BaggageItem({
  arItem,
  active,
  disabled,
  onBaggageSelect,
  purchased,
  displayTag,
}: {
  arItem: SelectableArItem;
  active: boolean;
  disabled: boolean;
  purchased: boolean;
  displayTag: boolean;
  onBaggageSelect: (arItem: SelectableArItem, active: boolean) => void;
}) {
  const { P } = useCurrency();
  const included = !!arItem.arMetaData?.included;
  const { t } = useTransContent();
  const { isMobile } = useMediaQueries();
  const lang = useParams().lang || "";
  return (
    <Card
      tabIndex={(!disabled || active) && !purchased && !included ? 0 : -1}
      onClick={() =>
        (!disabled || active) && !purchased && !included && onBaggageSelect && onBaggageSelect(arItem, active)
      }
      size='s'
      withBGC={active && !included ? "primary-light" : undefined}
      bodC={active && !included ? "active" : disabled ? "disabled" : "default"}
      sx={{
        position: "relative",
        overflow: "initial !important",
        ":focus-visible": {
          boxShadow: EShadows.focus,
          outline: "none",
        },
      }}
    >
      <CardContent sx={{ height: "100%" }}>
        <Box
          sx={{
            position: "absolute",
            top: `-${ESpacing._xs}`,
            display: displayTag ? "block" : "none",
            left:
              lang === "en" || lang === "en-HK"
                ? isMobile
                  ? "12%"
                  : "15%"
                : lang === "ja"
                  ? "23%"
                  : lang === "zh-HK" || lang === "zh-TW" || lang === "zh-CN"
                    ? isMobile
                      ? "26%"
                      : "30%"
                    : lang === "ko"
                      ? "20%"
                      : null,
          }}
        >
          <TagSmallStyle>
            <Font variant={EFont.p3} fontWeight='bolder'>
              {t("web.addExtras.baggage.tags")}
            </Font>
          </TagSmallStyle>
        </Box>
        <Stack
          alignItems='center'
          sx={{ minHeight: "72px", minWidth: ["69px", "69px", "80px"], cursor: "pointer" }}
          role='button'
          justifyContent={
            (active === true && arItem.code === EBaggageArCode._0KG) || purchased || included
              ? "flex-start"
              : "space-between"
          }
        >
          <Font
            fontWeight='fontWeightBold'
            variant={isMobile ? EFont.p3 : EFont.p1}
            color={(!active && disabled) || included ? "neutral.disable" : "neutral.black"}
          >
            {`${arItem.code === EBaggageArCode._0KG || included ? "" : "+"}${t(`${arItem.code}.title.short`)}`}
          </Font>
          {(active === false || arItem.code !== EBaggageArCode._0KG) && (
            <>
              {included && (
                <Font
                  variant={EFont.p3}
                  color={(!active && disabled) || included ? "neutral.disable" : "neutral.black"}
                >
                  {included ? t("web.addExtras.baggageSection") : ""}
                </Font>
              )}
              {purchased && (
                <Font
                  variant={EFont.p3}
                  color={(!active && disabled) || included ? "neutral.disable" : "neutral.black"}
                >
                  {purchased && arItem.code !== EBaggageArCode._0KG ? "added" : ""}
                </Font>
              )}
              {!purchased && !included && (
                <Font
                  fontWeight='fontWeightBold'
                  variant={isMobile ? EFont.p3 : EFont.p1}
                  color={(!active && disabled) || included ? "neutral.disable" : "purple.default"}
                >
                  {P(arItem.price ?? 0)}
                </Font>
              )}
            </>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
}
