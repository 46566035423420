import { formatISO } from "date-fns";
import { orderCreateAddAncilieoB2B } from "lib/features/b2b/agent-portal/service";
import { constructB2BMMBOrderCreateBody } from "lib/features/b2b/payment/helper";
import { getErrorDetail } from "lib/features/b2b/payment/mmbErrorCodeMapping";
import { getPaymentSession } from "lib/features/flight-book/booking/service";
import {
  CreateOrderResponse,
  CreatePaymentApiProps,
  ErrorType,
  GetOrderStatusResponse,
  PaymentMethod,
} from "lib/features/flight-book/payment/definition";
import { isCCMethod } from "lib/features/flight-book/payment/helper";
import {
  createCCpayment,
  createPayment,
  creditDataExchange,
  encryptWithJwk,
  generateSignature,
  getOrderStatus,
  getPaymentCallbackUrl,
} from "lib/features/flight-book/payment/service";
import { ManageMyBookingTripDetail } from "lib/features/manage-my-booking/my-trips/definition";
import { InitMMBPaymentSaga } from "lib/features/manage-my-booking/my-trips/payment/definition";
import {
  closeLoadingBackdrop,
  showLoadingBackdrop,
} from "modules/common/loading-backdrop/actions/LoadingBackdropAction";
import { Effect, call, cancel, delay, fork, put, take, takeEvery } from "redux-saga/effects";
import { RouterInstance } from "router/router-utils";
import store from "store";
import { storeCCPaymentMetaData } from "store/sessionStorage/slices/ccPaymentMetaData";
import { storeErrorModalDetail } from "store/sessionStorage/slices/errorModalDetail";
import { v4 } from "uuid";
import { INIT_B2B_MMB_PAYMENT_SAGA, createB2BMMBFormAction } from "../actions";

function* createMMBOrderId(
  cash_amount = 0,
  currency: string,
  booking: ManageMyBookingTripDetail,
  pay_by_organization_credit: boolean
): Generator<Effect, void, any> {
  try {
    const requestBody = yield call(constructB2BMMBOrderCreateBody, {
      booking,
      cash_amount,
      currency,
      pay_by_organization_credit,
    });

    const { order_id, order_type }: CreateOrderResponse = yield call(orderCreateAddAncilieoB2B, requestBody);
    if (order_id) {
      yield put({ type: "CREATE_MMB_PAYMENT_SAGA", order_id, order_type });
    } else {
      yield put({ type: "CANCEL_PAYMENT_SAGA" });
    }
  } catch (e) {
    yield put({ type: "CANCEL_PAYMENT_SAGA" });
    yield put(closeLoadingBackdrop());
  }
}

function* initMMBCCpayment(
  { creditCardDetail, paymentMethod }: InitMMBPaymentSaga,
  order_id: string
): Generator<Effect, void, any> {
  if (!creditCardDetail) return;
  const dataExchangeResp = yield call(creditDataExchange, {
    AccountNumber: creditCardDetail.cardNumber.replace(/\s/g, ""),
  });

  if (dataExchangeResp.jwt) {
    yield put(storeCCPaymentMetaData({ creditDataExchange: dataExchangeResp }));
  }
}

function* createMMBCCpaymentSaga(
  paymentDetail: InitMMBPaymentSaga,
  order_id: string,
  order_type: string,
  tds_md: string,
  afy_tid: string
): Generator<Effect, void, any> {
  const { creditCardDetail, paymentMethod, convenience_fee = 0, ccToken } = paymentDetail;
  if (!creditCardDetail || !ccToken) return;
  const { cardNumber, expiryDate, cardHolderName, cv2Number } = creditCardDetail;
  const nonce = v4();
  const timestamp = formatISO(new Date());
  const CCPaymentRequestBody = {
    order_id: order_id,
    device_type: "WEB",
    locale: "en_hk",
    success_url: getPaymentCallbackUrl(1, paymentDetail.paymentMethod),
    failure_url: getPaymentCallbackUrl(0, paymentDetail.paymentMethod),
    payment_option: paymentMethod,
    convenience_fee,
    cc_payload: {
      card_number: encryptWithJwk(ccToken, cardNumber.replace(/\s/g, "")),
      card_expiry: encryptWithJwk(ccToken, expiryDate),
      card_cvc: encryptWithJwk(ccToken, cv2Number),
      card_holder_name: cardHolderName,
      nonce: nonce,
      timestamp: timestamp,
      sig: generateSignature(cardNumber.replace(/\s/g, ""), expiryDate, cv2Number, nonce, timestamp),
      tds_md,
      afy_tid,
    },
  };
  try {
    const rest = yield call(createCCpayment, CCPaymentRequestBody, true);
    if (rest.txn_id) {
      // yield call(retrieveOrderStatus, order_id);
      yield put({ type: "RETRIEVE_MMB_ORDER_STATUS" });
    }
    return rest;
  } catch (error: any) {
    yield put({ type: "CANCEL_PAYMENT_SAGA" });
    yield put(closeLoadingBackdrop());
    const errorDetail = getErrorDetail(error, undefined, { order_id });
    if (errorDetail) {
      yield put(storeErrorModalDetail(errorDetail));
    } else {
      yield put(
        storeErrorModalDetail({
          type: ErrorType.M,
          title: `web.payment.paymentFail.title`,
          desc: `web.payment.paymentFail.${error.error_code}.desc`,
          onClose: () => {},
          data: {
            trace_id: error?.metadata?.trace_id,
            tReplaceData: {
              title: {},
              desc: { times: remaining_payment_retry_count, error_code: error.error_code },
            },
          },
        })
      );
    }
  }
}

function* createMMBPaymentSaga(
  order_id: string,
  order_type: string,
  paymentDetail: InitMMBPaymentSaga
): Generator<Effect, void, any> {
  const { paymentMethod, convenience_fee = 0 } = paymentDetail;
  if (!paymentMethod) {
    yield put({ type: "CANCEL_PAYMENT_SAGA" });
    return;
  }
  try {
    const rest = yield call(
      createPayment,
      {
        order_id: order_id,
        device_type: "WEB",
        locale: "en_hk",
        success_url: getPaymentCallbackUrl(1, paymentDetail.paymentMethod),
        failure_url: getPaymentCallbackUrl(0, paymentDetail.paymentMethod),
        payment_option: paymentMethod,
        convenience_fee,
      } as CreatePaymentApiProps,
      true
    );
    if (rest.txn_id) {
      // yield call(retrieveOrderStatus, order_id);
      yield put({ type: "RETRIEVE_MMB_ORDER_STATUS" });
    }

    return rest;
  } catch (error: any) {
    yield put({ type: "CANCEL_PAYMENT_SAGA" });
    yield put(closeLoadingBackdrop());
    const errorDetail = getErrorDetail(error, undefined, { order_id });
    if (errorDetail) {
      yield put(storeErrorModalDetail(errorDetail));
    } else {
      yield put(
        storeErrorModalDetail({
          type: ErrorType.M,
          title: `web.payment.paymentFail.title`,
          desc: `web.payment.paymentFail.${error.error_code}.desc`,
          onClose: () => {},
          data: {
            trace_id: error?.metadata?.trace_id,
            tReplaceData: {
              title: {},
              desc: { times: remaining_payment_retry_count, error_code: error.error_code },
            },
          },
        })
      );
    }
  }
}
let remaining_payment_retry_count = 3;
function* retrieveOrderStatus(
  order_id: string,
  booking: ManageMyBookingTripDetail,
  order_type?: string,
  paymentDetail?: Partial<InitMMBPaymentSaga>
): Generator<Effect, any, any> {
  while (true) {
    const response: GetOrderStatusResponse = yield call(getOrderStatus, order_id, true);
    remaining_payment_retry_count = response.remaining_payment_retry_count;
    if (response.txn_id || response.order_status === "S_COMP") {
      if (
        response?.payment_error?.error_code ||
        response.cash_payment_status === "S_CANL" ||
        response.cash_payment_status === "F_AUTH"
      ) {
        yield put({ type: "CANCEL_PAYMENT_SAGA" });
        const errorCode = response?.payment_error?.error_code || response.cash_payment_status;
        if (remaining_payment_retry_count === 0) {
          yield put(
            storeErrorModalDetail({
              type: ErrorType.M,
              title: "web.payment.paymentErrorXTimes.error.title",
              desc: "web.payment.paymentErrorXTimes.error.desc",
              onClose: () => {
                RouterInstance.replace(`/:lang/b2b/agent-portal/detail?bookingRef=${booking.sales_reference}`);
              },
              data: {
                trace_id: "web.payment.paymentErrorXTimes.error",
                tReplaceData: {
                  title: {},
                  desc: { times: remaining_payment_retry_count, error_code: errorCode },
                },
              },
            })
          );
        } else {
          yield put(
            storeErrorModalDetail({
              type: ErrorType.M,
              title: `web.payment.paymentFail.title`,
              desc: `web.payment.paymentFail.${errorCode}.desc`,
              onClose: async () => {
                // if(response.cash_payment_status === 'S_CANL' || response.cash_payment_status === "F_AUTH"){
                //   await cancelPayment(order_id)
                // };
              },
              data: {
                trace_id: response.metadata.trace_id,
                tReplaceData: {
                  title: {},
                  desc: {
                    times: remaining_payment_retry_count,
                    error_code: errorCode,
                  },
                },
              },
            })
          );
        }
      } else {
        if (response.order_status === "F_COMP") {
          yield put(
            createB2BMMBFormAction({
              form_action: response.form_action,
              form_url: response.form_value,
              order_id: order_id,
            })
          );
          yield put(
            storeErrorModalDetail({
              type: ErrorType.M,
              title: `web.payment.paymentFail.title`,
              desc: `web.payment.paymentFail.${response.order_status}.desc`,
              onClose: () => {
                RouterInstance.push(`/:lang/agent-portal/detail?bookingRef=${booking.sales_reference}`);
              },
              data: {
                trace_id: "F_COMP",
                tReplaceData: {
                  title: {},
                  desc: { times: remaining_payment_retry_count, error_code: response.order_status },
                },
              },
            })
          );
          yield put({ type: "CANCEL_PAYMENT_SAGA" });
          break;
        } else if (response.order_status === "S_COMP") {
          yield put(
            createB2BMMBFormAction({
              form_action: response.form_action,
              form_url: response.form_value,
              order_id: order_id,
              order_status: "S_COMP",
            })
          );
          yield put(showLoadingBackdrop());
          RouterInstance.push("/:lang/confirmation?" + new URLSearchParams({ typeFlow: "B2B_MMB" }));
          yield put({ type: "CANCEL_PAYMENT_SAGA" });
          break;
        } else if (response.order_status === "PEND") {
          yield put(
            createB2BMMBFormAction({
              form_action: response.form_action,
              form_url: response.form_value,
              order_id: order_id,
              order_type,
              error: response.payment_error,
            })
          );
        }
      }
    }
    yield delay(5000);
  }
}
function* initB2BMMBPaymentSaga(action: any): Generator<Effect, void, any> {
  yield put(showLoadingBackdrop());
  let createOrderTask = undefined;
  const {
    booking,
    cash_amount,
    paymentMethod,
    currency,
    order_id: prev_order_id,
    order_type: prev_order_type,
  } = action.payload as InitMMBPaymentSaga;
  let payment_order_id = "";
  let payment_order_type = "";
  const pay_by_organization_credit = paymentMethod === PaymentMethod.AG ? true : false;
  if (!prev_order_id || !prev_order_type) {
    createOrderTask = yield fork(createMMBOrderId, cash_amount, currency, booking, pay_by_organization_credit);
    const { order_id, order_type } = yield take("CREATE_MMB_PAYMENT_SAGA");
    if (createOrderTask) {
      yield cancel(createOrderTask);
    }
    payment_order_id = order_id;
    payment_order_type = order_type;
  } else {
    if (remaining_payment_retry_count === 0) {
      yield put({ type: "CANCEL_PAYMENT_SAGA" });
      yield put(
        storeErrorModalDetail({
          type: ErrorType.M,
          title: "web.payment.paymentErrorXTimes.error.title",
          desc: "web.payment.paymentErrorXTimes.error.desc",
          onClose: () => {
            store.dispatch(showLoadingBackdrop());
            RouterInstance.replace(`/:lang/b2b/agent-portal/detail?bookingRef=${booking.sales_reference}`);
          },
          data: {
            trace_id: "web.payment.paymentErrorXTimes.error",
            tReplaceData: {
              title: {},
              desc: {
                times: remaining_payment_retry_count,
                error_code: "PAY_PAYMENT_LIMIT_EXCEEDED",
              },
            },
          },
        })
      );
      yield put(closeLoadingBackdrop());
      payment_order_id = "";
      payment_order_type = "";
    } else {
      payment_order_id = prev_order_id;
      payment_order_type = prev_order_type;
    }
  }

  let createPaymentTask: any;
  let orderStatusTask = undefined;
  if (payment_order_id || payment_order_type) {
    if (payment_order_type === "NO_PAYMENT_ORDER") {
      orderStatusTask = yield fork(retrieveOrderStatus, payment_order_id, booking, payment_order_type, action.payload);
    } else if (isCCMethod(paymentMethod)) {
      yield put(
        storeCCPaymentMetaData({
          orderNumber: payment_order_id,
          amount: cash_amount,
        })
      );
      createPaymentTask = yield fork(initMMBCCpayment, action.payload, payment_order_id);
      const { md, afy_tid, error } = yield take("CREATE_CC_PAYMENT");
      if (error) {
        const { remaining_retry_count: remaining_payment_retry_count } = yield call(
          getPaymentSession,
          payment_order_id
        );
        if (remaining_payment_retry_count === 0) {
          yield put({ type: "CANCEL_PAYMENT_SAGA" });
          yield put(
            storeErrorModalDetail({
              type: ErrorType.M,
              title: "web.payment.paymentErrorXTimes.error.title",
              desc: "web.payment.paymentErrorXTimes.error.desc",
              onClose: () => {
                RouterInstance.replace(`/:lang/b2b/agent-portal/detail?bookingRef=${booking.sales_reference}`);
              },
              data: {
                trace_id: "web.payment.paymentErrorXTimes.error",
                tReplaceData: {
                  title: {},
                  desc: {
                    desc: { times: remaining_payment_retry_count, error_code: error?.error_code },
                  },
                },
              },
            })
          );
        } else {
          yield put(closeLoadingBackdrop());
          const errorDetail = getErrorDetail(error, undefined, {
            sales_reference: booking.sales_reference,
            order_id: payment_order_id,
          });
          if (errorDetail) {
            yield put({ type: "CANCEL_PAYMENT_SAGA" });
            yield put(storeErrorModalDetail(errorDetail));
          } else {
            yield put(
              storeErrorModalDetail({
                type: ErrorType.M,
                title: `web.payment.paymentFail.title`,
                desc: `web.payment.paymentFail.${error.error_code}.desc`,
                onClose: () => {
                  // await cancelPayment(payment_order_id);
                },
                data: {
                  trace_id: error?.metadata?.trace_id,
                  tReplaceData: {
                    title: {},
                    desc: { times: remaining_payment_retry_count, error_code: error.error_code },
                  },
                },
              })
            );
          }
        }
      } else {
        yield fork(createMMBCCpaymentSaga, action.payload, payment_order_id, payment_order_type, md, afy_tid);
      }
    } else {
      createPaymentTask = yield fork(createMMBPaymentSaga, payment_order_id, payment_order_type, action.payload);
    }

    if (!orderStatusTask) {
      yield take("RETRIEVE_MMB_ORDER_STATUS");
      orderStatusTask = yield fork(retrieveOrderStatus, payment_order_id, booking, payment_order_type, action.payload);
    }
  }
  yield take("CANCEL_PAYMENT_SAGA");
  yield put(closeLoadingBackdrop());
  yield cancel(createOrderTask);
  yield cancel(createPaymentTask);
  yield cancel(orderStatusTask);
}

export function* mmbB2BPaymentSaga() {
  yield takeEvery(INIT_B2B_MMB_PAYMENT_SAGA, initB2BMMBPaymentSaga);
}
