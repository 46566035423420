import { Journey } from "@hkexpressairwayslimited/ui";
import { add } from "lib/common/helper";
import { MMBJourney } from "lib/features/manage-my-booking/my-trips/definition";
import { useMemo } from "react";
import { EArItemType } from "ui/features/flight-booking/add-extras";

export function useSeatCost(selectedJourney?: Journey[] | MMBJourney[]) {
  return useMemo(() => {
    return (
      selectedJourney?.reduce(
        (a, journey) =>
          add(
            a,
            journey.segments.reduce(
              (a, segment) =>
                add(
                  a,
                  segment.arItems.selected.reduce((a, arItem) => {
                    if (arItem.type === EArItemType.SEAT && arItem.price !== undefined) {
                      return arItem.spoilage
                        ? add(a, arItem.price - arItem.spoilage >= 0 ? arItem.price - arItem.spoilage : 0)
                        : add(a, arItem.price);
                    } else return a;
                  }, 0)
                ),
              0
            )
          ),
        0
      ) ?? 0
    );
  }, [selectedJourney]);
}
