import { endpoints, fetchAPI } from "api";
import { envConfig } from "env";
import { getAccessToken, getMlcAccessToken } from "modules/features/auth/utils/authUtil";

export const requiredHeader = () => {
  return {
    "X-SIGNATURE": "dummy",
    "X-MLC-ACCESS-TOKEN": getMlcAccessToken() || "",
    Authorization: `Bearer ${getAccessToken() || ""}`,
  };
};

export const createNskToken = async (appCode: string) => {
  const requestBody = { application_code: appCode };
  const result = await fetchAPI(endpoints.createNskToken, {
    method: "POST",
    cache: "no-store",
    headers: { "Content-Type": "application/json", ...requiredHeader() },
    body: JSON.stringify(requestBody),
  });

  return result;
};

export const extendNskToken = async (
  token: string
): Promise<{
  metadata: {
    app_version: string;
    status_code: string;
    trace_id: string;
  };
  access_token: string;
}> => {
  const result = await fetchAPI(endpoints.createNskToken, {
    method: "PUT",
    cache: "no-store",
    headers: { "Content-Type": "application/json", ...requiredHeader(), nsk_token: token },
  });

  return result;
};

export const fetchI18nContent = async (lang: string) => {
  if (!envConfig.languageList.includes(lang)) {
    return undefined;
  }
  const urlParams = new URLSearchParams({ lang, application_code: "IBE", channel: "web" });
  const result = await fetchAPI<{ content?: Record<string, string> }>(`${envConfig.endpointContent}?${urlParams}`, {
    next: {
      revalidate: 60 * 5,
    },
    method: "GET",
  });

  return result;
};
